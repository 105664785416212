import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Col, Input, Row, Tabs } from 'antd';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import ContentLoader from '@sharedComponent/content-loader';
import EdenredButton from '@sharedComponent/button';
import EdenredEmployeesCard from '@sharedComponent/card/employees/employees-card';
import EdenredHeader from '@pages/main-layout/components/main-header';
import EdenredTypo from '@sharedComponent/typography';
import { ExcelDownload } from '@sharedComponent/excel/global-excel';
import ImageComponent from '@sharedComponent/image-component';
import Modalcarousel from '@sharedComponent/modal-carousel';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import { default as TableV2 } from '@pages/DesignSystem/Table/Table';
import Pagination from '@pages/DesignSystem/Table/Pagination';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import ETypo from '@pages/DesignSystem/Typo';
import { debounceHandler, entriesDropDown } from '@helpers';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import UserService from 'src/services/user-service';
import useOutsideClick from './useOutsideClick';
import useClaimsBasedContents from '../../hooks/useClaimsBasedContents/useClaimsBasedContents';
import { UnemploymentInsuranceShortBanner } from '@pages/payroll/run-payroll/components/unemployment-insurance-short-banner';
import ManageEmployeeHeader from './employees/ManageEmployeeHeader';

const { TabPane } = Tabs;

const ManageEmployeePresentational = (props) => {
	let {
		columns,
		dataSource,
		selectedEmployees,
		checkbox,
		handleCheckBox,
		totalEmpSelect,
		handlepassdata1,
		employetableSelect,
		employetableNotSelect,
		employeeCount,
		isDataLoading,
		filterData,
		selectedEmployee,
		selectedTab,
		enableFooter,
		activeAccountPagination,
		creatingAccountPagination,
		verifyApprovalPagination,
		deletedAccountPagination,
		handleTableChange,
		totalCount,
		employeeListForDownload,
		onSearch,
		buttonRef,
		activeTabs,
		isSubcribed,
		userNavigationMenu,
		getEmployeeDetailsForDownload,
		setDataCountToShow,
		dataCountToShow,
	} = props;
	const history = useHistory();
	const [filter, setFilter] = useState(false);
	const [visible, setVisible] = useState(false);
	// const [empCount, setEmpCount] = useState(0); Future Refernce
	const [height, setHeight] = useState(null);
	const [height2, setHeight2] = useState(null);
	const [height3, setHeight3] = useState(null);
	const [size, setSize] = useState(0);
	const ref = useRef();
	let zoom = window.innerHeight - 64;
	// let final =
	// 	zoom - (height + height2 + height3 - 90 - (width <= 1197 ? 32 : 0)) + (width >= 994 && width <= 1391 ? 35 : 0) + (width >= 1400 ? 43 : 0);
	// (width <= 1190 ? 43 : 0);
	// const handleClick = (e) => {
	// 	if (ref.current && !ref.current.contains(e.target)) {
	// 		callback();
	// 	}
	// }; Future Refernce

	const [popupNew, setPopupNew] = useState(false);
	const [nextSlide, setNextSlide] = useState(0);
	const MenuAPI = new UserService();
	let navbarClaims = useSelector((state) => state?.sharedStates?.userNavigation);
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits);
	const { shouldRender: renderBasedOnClaims } = useClaimsBasedContents();

	useEffect(() => {
		if (!isOnlyEmployeeExits && navbarClaims && (!navbarClaims?.tourGuide || !navbarClaims?.tourGuide?.includes('employee')) && isSubcribed) {
			setPopupNew(true);
		} else {
			setPopupNew(false);
		}
	}, [navbarClaims]);

	const handleClose = () => {
		setPopupNew(false);
	};
	const dataObj = [
		{
			image: not_focus_icons.Empolyee1,
			text: 'Your employees can request leaves, get announcements and download payslips',
		},
		{
			image: not_focus_icons.Empolyee2,
			text: 'Invite your employees with Bank Account',
		},
		{
			image: not_focus_icons.Empolyee3,
			text: 'Your C3Pay employees can do it directly from the app',
		},
	];
	const handleSlideChange = (flag, status, value) => {
		let payload1 = {
			...navbarClaims,
			tourGuide: navbarClaims?.tourGuide ? `${navbarClaims?.tourGuide},${status}` : status,
		};
		let payload2 = {
			...navbarClaims,
			employeeContract: true,
		};
		if (nextSlide < dataObj?.length - 1) {
			setNextSlide((prev) => prev + 1);
		}
		const payload = value === 'close' ? payload2 : payload1;
		if (flag == false || value === 'close') {
			MenuAPI.postUserNavigationMenu(payload)
				.then((res) => {
					userNavigationMenu();
					setPopupNew(false);
				})
				.catch((error) => {
					setPopupNew(false);
				});
		}
	};
	const div = useCallback((node) => {
		if (node !== null) {
			setHeight(node.getBoundingClientRect().height);
		}
	}, []);
	const div2 = useCallback((node) => {
		if (node !== null) {
			setHeight2(node.getBoundingClientRect().height);
		}
	}, []);
	const div3 = useCallback((node) => {
		if (node !== null) {
			setHeight3(node.getBoundingClientRect().height);
		}
	}, []);

	// useEffect(() => {
	// 	let totalFilterCount = selectedFilterCount.payrollChannels + selectedFilterCount.accountTypes + selectedFilterCount.establishments+ selectedFilterCount.bankNames;
	// 	setEmpCount(totalFilterCount)
	// }, [selectedFilterCount]) Future Refernce
	function useWindowSize() {
		useLayoutEffect(() => {
			function updateSize() {
				setSize(
					window.innerHeight -
						(60 +
							(window.innerWidth <= 1200 ? 82 : 50) +
							(window.innerWidth <= 1200 ? 254 : 222) -
							(window.innerWidth <= 1197 ? 32 : 0) +
							140 +
							(window.innerWidth <= 1000 ? 35 : 0))
				);
			}
			window.addEventListener('resize', updateSize);
			updateSize();
			return () => window.removeEventListener('resize', updateSize);
		}, []);
		return size;
	}
	// future ref
	// const handleFilterClick = (e) => {
	// 	if (window.screen.width <= 600) {
	// 		history.push({ pathname: '/employees/manage_employee/filter', state: { filterData } });
	// 		return;
	// 	}

	// 	setFilter(!filter);
	// };

	useOutsideClick(ref, () => {
		if (filter) setFilter(false);
	});

	let [searchedText, setSearchedText] = useState('');

	const tableLoading = {
		spinning: isDataLoading,
		indicator: <ContentLoader />,
	};

	let columnKeys = [
		{ labelKey: 'Name', valueKey: 'employeeName' },
		{ labelKey: 'Employee ID', valueKey: 'employeeCode' },
		{ labelKey: 'Account Number', valueKey: 'accountNumber' },
		{ labelKey: 'WPS Establishment ID', valueKey: 'establishmentId' },
		{ labelKey: 'Emirates ID Number', valueKey: 'emiratesId' },
		{ labelKey: 'Bank Routing Code', valueKey: 'bankRoutingCode' },
		{ labelKey: 'Card Created Date', valueKey: 'rhfreceivedDate' },
		{ labelKey: 'IBAN', valueKey: 'bankAccountNo' },
		{ labelKey: 'Bank Name', valueKey: 'bankName' },
		{ labelKey: 'WPS Person ID', valueKey: 'wpsPersonid' },
		{ labelKey: 'Passport Number', valueKey: 'passportNumber' },
	];
	if (activeTabs === '4') {
		let columndata = [
			{
				labelKey: 'Card Delete Date',
				valueKey: 'deletedDate',
			},
		];
		columnKeys = [...columnKeys, ...columndata];
	}

	if (activeTabs === '1' || activeTabs === '4') {
		let columndata = [
			{
				labelKey: 'Account Type',
				valueKey: 'accountType',
			},
		];
		columnKeys = [...columnKeys, ...columndata];
	}

	const showModal = () => setVisible(true);

	const handleBulkDelete = () => {
		ClevertapReact.event('Delete_BulkEmployees');
		history.push('/manange/employees/bulk/delete');
	};

	let tableData = [...dataSource];
	if (searchedText) {
		tableData = tableData?.filter((data) => {
			return (
				(data && data?.employeeName && data?.employeeName?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0) ||
				(data && data?.employeeCode && data?.employeeCode?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0) ||
				(data && data?.wpsPersonid && data?.wpsPersonid?.toLowerCase()?.indexOf(searchedText?.toLowerCase()) >= 0)
			);
		});
	}

	const handleUpdateBulk = () => history.push({ pathname: '/employee/update' });
	const activeEmployeeCount = employeeCount?.activeEmployeeCount || 0;
	const creatingAccountNoCount = employeeCount?.creatingAccountNoCount || 0;
	const pendingVerifierApprovalCount = employeeCount?.pendingVerifierApprovalCount || 0;
	const deleteAccountCount = employeeCount?.deleteAccountCount || 0;

	const handleDowloademployee = () => ClevertapReact.event('Download_Employees');

	return (
		<>
			{/* To display banner for non-subscribed unemployment insurance corprates */}
			<UnemploymentInsuranceShortBanner />
			{/* End */}
			<div style={{ width: '100%', padding: '0px 40px 0px 40px', marginBottom: '40px' }} className="display_none_max_width_600px">
				<EdenredHeader AddUsers />
				{/* web */}
				<ManageEmployeeHeader
					renderBasedOnClaims={renderBasedOnClaims}
					handleBulkDelete={handleBulkDelete}
					handleUpdateBulk={handleUpdateBulk}
				/>
				<EmployeeTabs
					selectedTab={selectedTab}
					activeEmployeeCount={activeEmployeeCount}
					handleTabChange={props.handleTabChange}
					creatingAccountNoCount={creatingAccountNoCount}
					pendingVerifierApprovalCount={pendingVerifierApprovalCount}
					deleteAccountCount={deleteAccountCount}
				/>
				<EmployeeTable
					selectedTab={selectedTab}
					creatingAccountPagination={creatingAccountPagination}
					verifyApprovalPagination={verifyApprovalPagination}
					deletedAccountPagination={deletedAccountPagination}
					activeAccountPagination={activeAccountPagination}
					columns={columns}
					dataSource={dataSource}
					totalCount={totalCount}
					handleTableChange={handleTableChange}
					onSearch={onSearch}
					employeeListForDownload={employeeListForDownload}
					getEmployeeDetailsForDownload={getEmployeeDetailsForDownload}
					handleDowloademployee={handleDowloademployee}
					tableLoading={tableLoading?.spinning}
					setDataCountToShow={setDataCountToShow}
					dataCountToShow={dataCountToShow}
					columnKeys={columnKeys}
					activeTabs={activeTabs}
					buttonRef={buttonRef}
					renderBasedOnClaims={renderBasedOnClaims}
				/>
			</div>
			<Row className="manage-employee-mobile-view">
				<Col xs={24} sm={24} className="manage-employee-mobile-header">
					<EdenredTypo>Employees</EdenredTypo>
				</Col>
				<Col xs={24} sm={24} className="manage-employee-mobile-filtersearch">
					<Row className="manage-employee-mobile-filtersearchcol">
						<Col xl={3} lg={6} md={7} xs={8} sm={8} className="mobile-filter">
							<Link
								to={{
									pathname: '/employees/manage_employee/filter',
									state: { filterData },
								}}>
								<Row className="manage-employee-mobile-filter">
									<ImageComponent src={icons.not_focus.Filter} />
									Filter
								</Row>
							</Link>
						</Col>
						<Col xl={10} lg={15} md={10} xs={16} sm={16}>
							<Input
								className="emp-search"
								prefix={<ImageComponent src={icons.not_focus.Search} />}
								placeholder="Search by Name or Employee ID."
								onInput={(e) => debounceHandler(e.target.value, onSearch)}
							/>
						</Col>
					</Row>
				</Col>
				<Col xs={24} sm={24} className="manage-employee-mobile-tab">
					<Tabs onChange={props.handleTabChange}>
						<TabPane tab={<span>Active Accounts ({activeEmployeeCount || 0})</span>} key="1">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : null}
								<Col xs={24} sm={24}>
									{employetableSelect?.map((tab, ind) => (
										<>
											<EdenredEmployeesCard
												type="ManageEmployeeTableCard"
												content={tab}
												style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
												ind={ind}
												className="card_content"
												handleCheckBox={handleCheckBox}
												handlepassdata={() => handlepassdata1('', tab)}
												selected={selectedEmployee}
											/>
										</>
									))}
								</Col>
								<EdenredTypo> Not Selected </EdenredTypo>
								<Col xs={24} sm={24}>
									<InfiniteScroll
										dataLength={totalEmpSelect}
										style={{ padding: '0 8px 20px 8px' }}
										next={(pagination) => handleTableChange(pagination + 1, 'activeAccountPagination')}
										hasMore={true}
										height={`${zoom}px`}
										endMessage={
											<p style={{ textAlign: 'center' }}>
												<b>Yay! You have seen it all</b>
											</p>
										}>
										{employetableNotSelect?.map((tab, ind) => (
											<EdenredEmployeesCard
												selected={selectedEmployee}
												type="ManageEmployeeTableCard"
												content={tab}
												style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
												ind={ind}
												className="card_content"
												handleCheckBox={handleCheckBox}
												handlepassdata={() => handlepassdata1('', tab)}
											/>
										))}
									</InfiniteScroll>
								</Col>
							</Col>
						</TabPane>
						<TabPane tab={<span>Creating Account No. ({employeeCount?.creatingAccountNoCount || 0})</span>} key="2">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={totalEmpSelect}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handleTableChange(pagination + 1, 'creatingAccountPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{employetableNotSelect?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											ind={ind}
											selected={selectedEmployee}
											handleCheckBox={handleCheckBox}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
						<TabPane tab={<span>Verifier Approval ({employeeCount?.pendingVerifierApprovalCount || 0})</span>} key="3">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={dataSource.length}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handleTableChange(pagination + 1, 'verifyApprovalPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{dataSource?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											checkbox={checkbox}
											handleCheckBox={handleCheckBox}
											ind={ind}
											selected={selectedEmployee}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
						<TabPane tab={<span>Deleted Accounts ({employeeCount?.deleteAccountCount || 0})</span>} key="4">
							<Col xs={24} sm={24} className="select">
								{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : <EdenredTypo> Not Selected</EdenredTypo>}
							</Col>
							<Col xs={24} sm={24}>
								<InfiniteScroll
									dataLength={totalEmpSelect}
									style={{ padding: '0 8px 20px 8px' }}
									next={(pagination) => handleTableChange(pagination + 1, 'deletedAccountPagination')}
									hasMore={true}
									height={`${zoom}px`}
									endMessage={
										<p style={{ textAlign: 'center' }}>
											<b>Yay! You have seen it all</b>
										</p>
									}>
									{dataSource?.map((tab, ind) => (
										<EdenredEmployeesCard
											type="ManageEmployeeTableCard"
											content={tab}
											className="card_content"
											checkbox={checkbox}
											handleCheckBox={handleCheckBox}
											ind={ind}
											selected={selectedEmployee}
											handlepassdata={() => handlepassdata1('', tab)}
										/>
									))}
								</InfiniteScroll>
							</Col>
						</TabPane>
					</Tabs>
				</Col>
				{enableFooter && (
					<>
						<Col xs={24} sm={24} className="manage-employee-mobile-add">
							<Link to={{ pathname: '/employees/add/multiple/employees/upload' }}>
								<EdenredButton>Add Employee</EdenredButton>
							</Link>
						</Col>
						<Col xs={24} sm={24} className="manage-employee-mobile-download">
							<ExcelDownload
								dataSet={selectedEmployees?.length > 0 ? selectedEmployees : dataSource ? dataSource : []}
								columnKeys={columnKeys}
								className="view-salary-history"
								filename={`employeelist`}
								activeTabs={activeTabs}
								element={<EdenredButton type="link">Download Employee Details</EdenredButton>}
							/>
						</Col>
					</>
				)}
				{enableFooter && ['1', '3']?.indexOf(selectedTab) >= 0 && totalEmpSelect > 0 ? (
					<Row className="delete_show" onClick={showModal}>
						<Col xs={24} sm={24} className="delete_employees_button">
							<EdenredButton>Delete {totalEmpSelect} Employee(s)</EdenredButton>
						</Col>
					</Row>
				) : null}
			</Row>
		</>
	);
};

const EmployeeTabs = ({
	selectedTab,
	activeEmployeeCount,
	handleTabChange,
	creatingAccountNoCount,
	pendingVerifierApprovalCount,
	deleteAccountCount,
}) => {
	return (
		<>
			<section className="tabs_section_wrapper">
				<div className={`tab first_child ${selectedTab === '1' && 'active'} `} onClick={() => handleTabChange('1')}>
					<span>Active Accounts</span>
					<span className={`badge ${selectedTab === '1' && 'active'} `}>{activeEmployeeCount}</span>
				</div>
				<div className={`tab ${selectedTab === '2' && 'active'} `} onClick={() => handleTabChange('2')}>
					<span>Creating Account No</span>
					<span className={`badge ${selectedTab === '2' && 'active'} `}>{creatingAccountNoCount}</span>
				</div>
				<div className={`tab ${selectedTab === '3' && 'active'} `} onClick={() => handleTabChange('3')}>
					<span>Pending Verifier Approval</span>
					<span className={`badge ${selectedTab === '3' && 'active'} `}>{pendingVerifierApprovalCount}</span>
				</div>

				<div className={`tab last_child ${selectedTab === '4' && 'active'} `} onClick={() => handleTabChange('4')}>
					<span>Deleted Accounts</span>
					<span className={`badge ${selectedTab === '4' && 'active'} `}>{deleteAccountCount}</span>
				</div>
			</section>
		</>
	);
};

const EmployeeTableHeader = ({
	onSearch,
	employeeListForDownload,
	getEmployeeDetailsForDownload,
	handleDowloademployee,
	setDataCountToShow,
	dataCountToShow,
	columnKeys,
	activeTabs,
	buttonRef,
	renderBasedOnClaims,
}) => {
	return (
		<>
			<section className="employee_table_header_wrapper">
				<div className="search_and_download_wrapper">
					<Input
						onInput={(e) => debounceHandler(e.target.value, onSearch)}
						className=" employee_manage_table_search"
						prefix={<ImageComponent src={icons.not_focus.Search} />}
						placeholder="Search by Name, Employee ID, Designation..."
					/>
					{renderBasedOnClaims?.downloadEmployeeButton && (
						<>
							<EdenredButtons
								loading={employeeListForDownload?.loading}
								onClick={() => {
									getEmployeeDetailsForDownload();
									handleDowloademployee();
								}}
								type="outline-g"
								leftIcon={<ImageComponent className="leftIcon" src={not_focus_icons.documentDownload_v2} />}
								btnType="secondary">
								Download Employee List
							</EdenredButtons>
							<ExcelDownload
								dataSet={employeeListForDownload?.data}
								columnKeys={columnKeys}
								className="view-salary-history"
								filename={`employeelist`}
								activeTabs={activeTabs}
								element={<button style={{ display: 'none' }} ref={buttonRef}></button>}
							/>
						</>
					)}
				</div>
				<div className="no_of_data_to_show">
					<p>show</p>
					<MultiSelect
						onChange={(data) => setDataCountToShow(parseInt(data?.name))}
						Option={[
							{
								name: '10',
							},
							{
								name: '20',
							},
							{
								name: '30',
							},
						]}
						searchVisibility={false}
						value={`${dataCountToShow}`}
						dropDownValue={'name'}
						multiSelect={false}
					/>
					<p>Entries</p>
				</div>
			</section>
		</>
	);
};

const EmployeeTable = ({
	selectedTab,
	creatingAccountPagination,
	verifyApprovalPagination,
	deletedAccountPagination,
	activeAccountPagination,
	columns,
	dataSource,
	totalCount,
	handleTableChange,
	tableLoading,
	onSearch,
	employeeListForDownload,
	getEmployeeDetailsForDownload,
	handleDowloademployee,
	setDataCountToShow,
	dataCountToShow,
	columnKeys,
	activeTabs,
	buttonRef,
	renderBasedOnClaims,
}) => {
	const _currentTabPageSize = () => {
		switch (selectedTab) {
			case '2':
				return creatingAccountPagination?.pagination?.pageSize || 0;

			case '3':
				return verifyApprovalPagination?.pagination?.pageSize || 0;

			case '4':
				return deletedAccountPagination?.pagination?.pageSize || 0;

			default:
				return activeAccountPagination?.pagination?.pageSize || 0;
		}
	};

	const _currentTabCurrentPage = () => {
		switch (selectedTab) {
			case '2':
				return creatingAccountPagination?.pagination?.current || 0;

			case '3':
				return verifyApprovalPagination?.pagination?.current || 0;

			case '4':
				return deletedAccountPagination?.pagination?.current || 0;

			default:
				return activeAccountPagination?.pagination?.current || 0;
		}
	};

	const _currentPage = () => {
		switch (selectedTab) {
			case '2':
				return 'creatingAccountPagination';

			case '3':
				return 'verifyApprovalPagination';

			case '4':
				return 'deletedAccountPagination';

			default:
				return 'activeAccountPagination';
		}
	};

	return (
		<>
			<section className="employee_manage_table_wrapper">
				<EmployeeTableHeader
					onSearch={onSearch}
					employeeListForDownload={employeeListForDownload}
					getEmployeeDetailsForDownload={getEmployeeDetailsForDownload}
					handleDowloademployee={handleDowloademployee}
					setDataCountToShow={setDataCountToShow}
					dataCountToShow={dataCountToShow}
					columnKeys={columnKeys}
					activeTabs={activeTabs}
					buttonRef={buttonRef}
					renderBasedOnClaims={renderBasedOnClaims}
				/>
				<TableV2 columns={columns} data={dataSource} tableLoading={tableLoading} />
				<dev className="pagination-bar employee_table_pagination_wrapper ">
					<ETypo light b3 color="#484B52">
						Showing{' '}
						{dataSource?.length <= activeAccountPagination?.pagination?.pageSize
							? dataSource?.length
							: activeAccountPagination?.pagination?.pageSize}{' '}
						out of {totalCount} employees
					</ETypo>
					<Pagination
						className="pagination-bar padding_0"
						currentPage={_currentTabCurrentPage()}
						totalCount={totalCount || 1}
						pageSize={_currentTabPageSize()}
						onPageChange={(page) => handleTableChange({ current: page }, _currentPage())}
					/>
				</dev>
			</section>
		</>
	);
};

export default ManageEmployeePresentational;

// future ref
// <>
// 				<Row
// 					style={{ overflow: visible ? 'hidden' : 'visible' }}
// 					className={tableData.length > 0 ? 'Manage-employee-container' : 'Manage-employee-container Manage-emp-nodata'}
// 					ref={div3}>
// 					{/* header */}
// 					<Col xl={24} lg={24} md={24} xs={24} sm={24} className="manage-employee-header" ref={div}>
// 						<Col xl={8} lg={8} md={5} xs={5} sm={5}>
// 							<EdenredTypo>Employees</EdenredTypo>
// 						</Col>
// 					</Col>
// 					{/*tab  */}
// 					<Col xl={24} lg={24} md={24} xs={24} sm={24} className="manage-employee-tab">
// 						<Tabs defaultActiveKey="1" onChange={props.handleTabChange}>
// 							<TabPane tab={<span>Active Accounts ({activeEmployeeCount})</span>} key="1">
// 								<Col xl={24} lg={24} md={24} xs={24} sm={24} className="manage-employee-col">
// 									{/* filter and search */}
// 									{renderFilterCommon()}
// 									{/* table filter */}
// 									<Row className="manage-employees-table">
// 										<Table
// 											className="manage-employee-table"
// 											loading={tableLoading}
// 											columns={columns}
// 											scroll={{ y: 1500 }}
// 											dataSource={tableData}
// 											pagination={{ ...activeAccountPagination, total: totalCount, ...defaultSettings }}
// 											onChange={(pagination) => handleTableChange(pagination, 'activeAccountPagination')}
// 											locale={locale}
// 										/>
// 									</Row>
// 									{/* footer */}
// 								</Col>
// 							</TabPane>
// 							<TabPane tab={<span>Creating Account ({creatingAccountNoCount})</span>} key="2" className="manage-employee-col">
// 								{/* filter and search */}
// 								{renderFilterCommon()}
// 								<Row className="manage-employees-table">
// 									<Table
// 										className="manage-employee-table"
// 										loading={tableLoading}
// 										columns={columns}
// 										scroll={{ x: 900, y: `${final}px` }}
// 										dataSource={tableData}
// 										pagination={{ ...creatingAccountPagination, total: totalCount, ...defaultSettings }}
// 										onChange={(pagination) => handleTableChange(pagination, 'creatingAccountPagination')}
// 										locale={locale}
// 									/>
// 								</Row>
// 							</TabPane>
// 							<TabPane
// 								tab={<span>Pending Verifier Approval ({pendingVerifierApprovalCount})</span>}
// 								key="3"
// 								className="manage-employee-col">
// 								{/* filter and search */}
// 								{renderFilterCommon()}
// 								<Row className="manage-employees-table">
// 									<Table
// 										className="manage-employee-table"
// 										loading={tableLoading}
// 										columns={columns}
// 										scroll={{ x: 900, y: `${final}px` }}
// 										dataSource={tableData}
// 										pagination={{ ...verifyApprovalPagination, total: totalCount, ...defaultSettings }}
// 										onChange={(pagination) => handleTableChange(pagination, 'verifyApprovalPagination')}
// 										locale={locale}
// 									/>
// 								</Row>
// 							</TabPane>
// 							<TabPane tab={<span>Deleted Accounts ({deleteAccountCount})</span>} key="4" className="manage-employee-col">
// 								{/* filter and search */}
// 								{renderFilterCommon()}
// 								<Row className="manage-employees-table">
// 									<Table
// 										className="manage-employee-table"
// 										loading={tableLoading}
// 										columns={columns}
// 										scroll={{ x: 900, y: `${final}px` }}
// 										dataSource={tableData}
// 										pagination={{ ...deletedAccountPagination, total: totalCount, ...defaultSettings }}
// 										onChange={(pagination) => handleTableChange(pagination, 'deletedAccountPagination')}
// 										locale={locale}
// 									/>
// 								</Row>
// 							</TabPane>
// 						</Tabs>
// 					</Col>

// 					{/* footer */}
// 					{enableFooter && (
// 						<Col xl={23} lg={23} md={23} xs={24} sm={24} className="manage-employee-footer">
// 							<Row className="manage-employee-row">
// 								<div className="emp-parent">
// 									<div className="manage-employee-delete"></div>
// 									<div xl={9} lg={9} md={5} className="manage-employee-add">
// 										<Link to={{ pathname: '/employees/add/multiple/employees/upload' }}>
// 											<EdenredButton onClick={() => handleEmployee()}>Add Employees</EdenredButton>
// 										</Link>
// 									</div>
// 								</div>
// 							</Row>
// 						</Col>
// 					)}
// 				</Row>

// 				{/* mobile view */}

// 				<Row className="manage-employee-mobile-view">
// 					<Col xs={24} sm={24} className="manage-employee-mobile-header">
// 						<EdenredTypo>Employees</EdenredTypo>
// 					</Col>
// 					<Col xs={24} sm={24} className="manage-employee-mobile-filtersearch">
// 						<Row className="manage-employee-mobile-filtersearchcol">
// 							<Col xl={3} lg={6} md={7} xs={8} sm={8} className="mobile-filter">
// 								<Link
// 									to={{
// 										pathname: '/employees/manage_employee/filter',
// 										state: { filterData },
// 									}}>
// 									<Row className="manage-employee-mobile-filter">
// 										<ImageComponent src={icons.not_focus.Filter} />
// 										Filter
// 									</Row>
// 								</Link>
// 							</Col>
// 							<Col xl={10} lg={15} md={10} xs={16} sm={16}>
// 								<Input
// 									className="emp-search"
// 									prefix={<ImageComponent src={icons.not_focus.Search} />}
// 									placeholder="Search by Name or Employee ID."
// 									onInput={(e) => debounceHandler(e.target.value, onSearch)}
// 								/>
// 							</Col>
// 						</Row>
// 					</Col>
// 					<Col xs={24} sm={24} className="manage-employee-mobile-tab">
// 						<Tabs onChange={props.handleTabChange}>
// 							<TabPane tab={<span>Active Accounts ({activeEmployeeCount || 0})</span>} key="1">
// 								<Col xs={24} sm={24} className="select">
// 									{totalEmpSelect > 0 ? <EdenredTypo>Selected {totalEmpSelect}</EdenredTypo> : null}
// 									<Col xs={24} sm={24}>
// 										{employetableSelect.map((tab, ind) => (
// 											<>
// 												<EdenredEmployeesCard
// 													type="ManageEmployeeTableCard"
// 													content={tab}
// 													style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
// 													ind={ind}
// 													className="card_content"
// 													handleCheckBox={handleCheckBox}
// 													handlepassdata={() => handlepassdata1('', tab)}
// 													selected={selectedEmployee}
// 												/>
// 											</>
// 										))}
// 									</Col>
// 									<EdenredTypo> Not Selected </EdenredTypo>
// 									<Col xs={24} sm={24}>
// 										<InfiniteScroll
// 											dataLength={totalEmpSelect}
// 											style={{ padding: '0 8px 20px 8px' }}
// 											next={(pagination) => handleTableChange(pagination + 1, 'activeAccountPagination')}
// 											hasMore={true}
// 											height={`${zoom}px`}
// 											endMessage={
// 												<p style={{ textAlign: 'center' }}>
// 													<b>Yay! You have seen it all</b>
// 												</p>
// 											}>
// 											{employetableNotSelect.map((tab, ind) => (
// 												<EdenredEmployeesCard
// 													selected={selectedEmployee}
// 													type="ManageEmployeeTableCard"
// 													content={tab}
// 													style={{ boxShadow: tab.isSelected === true ? '0px 10px 20px rgba(174, 182, 183, 0.5)' : null }}
// 													ind={ind}
// 													className="card_content"
// 													handleCheckBox={handleCheckBox}
// 													handlepassdata={() => handlepassdata1('', tab)}
// 												/>
// 											))}
// 										</InfiniteScroll>
// 									</Col>
// 								</Col>
// 							</TabPane>
// 							<TabPane tab={<span>Creating Account No. ({employeeCount.creatingAccountNoCount || 0})</span>} key="2">
// 								<Col xs={24} sm={24} className="select">
// 									{/* <EdenredTypo> Not Selected</EdenredTypo> */}
// 									{totalEmpSelect > 0 ? (
// 										<EdenredTypo>Selected {totalEmpSelect}</EdenredTypo>
// 									) : (
// 										<EdenredTypo> Not Selected</EdenredTypo>
// 									)}
// 								</Col>
// 								<Col xs={24} sm={24}>
// 									<InfiniteScroll
// 										dataLength={totalEmpSelect}
// 										style={{ padding: '0 8px 20px 8px' }}
// 										next={(pagination) => handleTableChange(pagination + 1, 'creatingAccountPagination')}
// 										hasMore={true}
// 										height={`${zoom}px`}
// 										endMessage={
// 											<p style={{ textAlign: 'center' }}>
// 												<b>Yay! You have seen it all</b>
// 											</p>
// 										}>
// 										{employetableNotSelect.map((tab, ind) => (
// 											<EdenredEmployeesCard
// 												type="ManageEmployeeTableCard"
// 												content={tab}
// 												className="card_content"
// 												ind={ind}
// 												selected={selectedEmployee}
// 												handleCheckBox={handleCheckBox}
// 												handlepassdata={() => handlepassdata1('', tab)}
// 											/>
// 										))}
// 									</InfiniteScroll>
// 								</Col>
// 							</TabPane>
// 							<TabPane tab={<span>Verifier Approval ({employeeCount.pendingVerifierApprovalCount || 0})</span>} key="3">
// 								<Col xs={24} sm={24} className="select">
// 									{totalEmpSelect > 0 ? (
// 										<EdenredTypo>Selected {totalEmpSelect}</EdenredTypo>
// 									) : (
// 										<EdenredTypo> Not Selected</EdenredTypo>
// 									)}
// 								</Col>
// 								<Col xs={24} sm={24}>
// 									<InfiniteScroll
// 										dataLength={dataSource.length}
// 										style={{ padding: '0 8px 20px 8px' }}
// 										next={(pagination) => handleTableChange(pagination + 1, 'verifyApprovalPagination')}
// 										hasMore={true}
// 										height={`${zoom}px`}
// 										endMessage={
// 											<p style={{ textAlign: 'center' }}>
// 												<b>Yay! You have seen it all</b>
// 											</p>
// 										}>
// 										{dataSource.map((tab, ind) => (
// 											<EdenredEmployeesCard
// 												type="ManageEmployeeTableCard"
// 												content={tab}
// 												className="card_content"
// 												checkbox={checkbox}
// 												handleCheckBox={handleCheckBox}
// 												ind={ind}
// 												selected={selectedEmployee}
// 												handlepassdata={() => handlepassdata1('', tab)}
// 											/>
// 										))}
// 									</InfiniteScroll>
// 								</Col>
// 							</TabPane>
// 							<TabPane tab={<span>Deleted Accounts ({employeeCount.deleteAccountCount || 0})</span>} key="4">
// 								<Col xs={24} sm={24} className="select">
// 									{totalEmpSelect > 0 ? (
// 										<EdenredTypo>Selected {totalEmpSelect}</EdenredTypo>
// 									) : (
// 										<EdenredTypo> Not Selected</EdenredTypo>
// 									)}
// 								</Col>
// 								<Col xs={24} sm={24}>
// 									<InfiniteScroll
// 										dataLength={totalEmpSelect}
// 										style={{ padding: '0 8px 20px 8px' }}
// 										next={(pagination) => handleTableChange(pagination + 1, 'deletedAccountPagination')}
// 										hasMore={true}
// 										height={`${zoom}px`}
// 										endMessage={
// 											<p style={{ textAlign: 'center' }}>
// 												<b>Yay! You have seen it all</b>
// 											</p>
// 										}>
// 										{dataSource.map((tab, ind) => (
// 											<EdenredEmployeesCard
// 												type="ManageEmployeeTableCard"
// 												content={tab}
// 												className="card_content"
// 												checkbox={checkbox}
// 												handleCheckBox={handleCheckBox}
// 												ind={ind}
// 												selected={selectedEmployee}
// 												handlepassdata={() => handlepassdata1('', tab)}
// 											/>
// 										))}
// 									</InfiniteScroll>
// 								</Col>
// 							</TabPane>
// 						</Tabs>
// 					</Col>
// 					{enableFooter && (
// 						<>
// 							<Col xs={24} sm={24} className="manage-employee-mobile-add">
// 								<Link to={{ pathname: '/employees/add/multiple/employees/upload' }}>
// 									<EdenredButton>Add Employee</EdenredButton>
// 								</Link>
// 							</Col>
// 							<Col xs={24} sm={24} className="manage-employee-mobile-download">
// 								<ExcelDownload
// 									dataSet={selectedEmployees.length > 0 ? selectedEmployees : dataSource ? dataSource : []}
// 									columnKeys={columnKeys}
// 									className="view-salary-history"
// 									filename={`employeelist`}
// 									activeTabs={activeTabs}
// 									// filename={`${moment(new Date()).format('MM/DD/YYYY')}_Employee_list`}
// 									element={<EdenredButton type="link">Download Employee Details</EdenredButton>}
// 								/>
// 							</Col>
// 						</>
// 					)}
// 					{enableFooter && ['1', '3'].indexOf(selectedTab) >= 0 && totalEmpSelect > 0 ? (
// 						<Row className="delete_show" onClick={showModal}>
// 							<Col xs={24} sm={24} className="delete_employees_button">
// 								<EdenredButton>Delete {totalEmpSelect} Employee(s)</EdenredButton>
// 							</Col>
// 						</Row>
// 					) : null}
// 				</Row>
// 				</>

export const MANAGE_EMPLOYEES_CONSTANTS = {
    addEmployees: {
        // This modal checking whether employee opted to tawjeeh or any external channels for Unemployement insurance       
        modalContents: {
            // Initial check of insurance opted for external or internal
            workPermitCheck: {
                title: 'Do you issue work permits through external channels or tawjeeh?',
                desc: 'In case yes, newly added employees may already have received Unemployment Insurance.',
                yesText: 'Yes',
                noText: 'No'
            },
            // If the employee opted for insurance externally
            insuranceExternal: {
                title: 'Do you want to stop adding New  C3pay cardholders to Unemployment Insurance?',
                desc: 'Due to recent MoHRE regulations, Some cardholders  are getting external policies through their work permit applications. ',
                employeeAddingDate: new Date(), // Currently using the current date
                yesText: 'Stop For New Employees',
                noText: 'Don’t Stop for New Employees'
            },
            // If the employee opted for insurance internally
            insuranceInternal: {
                title: 'Confirm Continuing Subscription for newly added employees',
                desc: `Since insurance for new employees wasn't obtained via Tawjeeh, do you want to continue with the mass subscription?`,
                yesText: 'Yes, Continue With Subscription',
                noText: 'No, Stop For New Employees'
            },
        }
    }
}
export const MFA_CONSTANTS = {
    mfa: {
        OFF: "OFF",
        SETUP: "SETUP",
        VERIFIED: "VERIFIED",
        REQUIRED: "REQUIRED",
        LOCKED: "LOCKED",
    },
    otpStatusVerify: {
        VERIFIED: "VERIFIED",
        LOCKED: "LOCKED",
        WRONG_OTP: "WrongOTP",
        UPDATED: "Updated"
    },
    otpReason: {
        mfa: 'MFA',
        update: 'UpdatePhoneNumber',
    },
    countryCode: '971',
    timerKey: 'ZTimer',
    lockedPeriod: '5 mins'
}
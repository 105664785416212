import React from 'react';
import { icons } from 'src/assets/icons';
import ClevertapReact from 'src/utils/clever-tap';
import FilterValue from './filter-value';

const FilterComponent = ({
	filterObject,
	selectedFilter,
	loading,
	setFilterObject,
	filterClearAll,
	setFilteClicked,
	active,
	filterRef,
	closeFilter,
	cleverTabEvent,
	handleupdate,
}) => {
	const handleFilterData = (label, value, name, labelKey, valueKey, index, isFilterSelected) => {
		const selectedDate = filterObject?.map((data) => {
			if (labelKey === data?.labelKey) {
				return {
					...data,
					filterOpen: data?.filterOpen,
					filterValue: data?.filterValue?.map((obj) => {
						if (valueKey === obj?.valueKey) {
							return {
								...obj,
								isLoading: loading,
								isSelected: !obj?.isSelected,
							};
						} else {
							return obj;
						}
					}),
				};
			}
			return data;
		});

		setFilterObject(selectedDate);

		// handleupdate(selectedDate);
		closeFilter && setFilteClicked(false);
		selectedFilter && selectedFilter({ label: label, value: value, name: name, index: index, selected: isFilterSelected });
	};

	const handeleFilter = (id) => {
		const isFilterOpen = filterObject?.map((item) => {
			if (id === item?.labelKey) {
				return {
					...item,
					filterOpen: filterObject?.length > 1 ? !item?.filterOpen : true,
				};
			}
			return {
				...item,
				filterOpen: false,
			};
		});
		setFilterObject(isFilterOpen);
	};
	const handleClearAll = () => {
		if (cleverTabEvent) {
			ClevertapReact.event(cleverTabEvent.Filter_ClearAll);
		}
		const filterClear = filterObject?.map((data) => {
			return {
				...data,
				count: 0,
				filterValue: data?.filterValue?.map((item) => {
					return {
						...item,
						isLoading: false,
						isSelected: false,
					};
				}),
			};
		});
		setFilterObject(filterClear);
		closeFilter && setFilteClicked(false);
		filterClearAll && filterClearAll();
	};

	const filterCount = (filterObject) => filterObject?.filter((item) => item?.isSelected)?.length;
	return (
		<div className="filter_main" ref={filterRef}>
			<div className="filter_header">
				<p className="filter_text">Filters</p>
				<p
					className={active ? 'filter_clear' : 'disabled_clear'}
					onClick={() => {
						if (active) {
							handleClearAll();
						}
					}}>
					Clear all
				</p>
			</div>
			{filterObject?.map((data) => (
				<>
					<div
						key={data?.labelKey}
						className={`filter_sub_header ${data?.filterOpen ? 'filter_bg' : ''}`}
						onClick={() => handeleFilter(data?.labelKey)}>
						<div className="filter_main_filter">
							<p className="filter_label">{data?.filterLabel}</p>
							<p className="filter_count">({filterCount(data?.filterValue) || 0})</p>
						</div>
						<img src={data?.filterOpen ? icons.not_focus.up : icons.not_focus.down} />
					</div>
					{data?.filterOpen && <FilterValue {...{ filterValue: data?.filterValue, filterLabel: data, handleFilterData }} />}
				</>
			))}
		</div>
	);
};

export default FilterComponent;

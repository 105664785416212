import { APP_VARIABLES, DOMAIN_URL } from '@constants/app-constants';
import { getLocalvariable } from '@helpers';
import Axios from 'axios';

class EmployeeService {
	baseUrl = `${DOMAIN_URL}/Employees`;
	empSalBaseUrl = `${DOMAIN_URL}`;
	transBaseUrl = `${DOMAIN_URL}/Transaction`;
	employeeVerificationUrl = `${DOMAIN_URL}/employee-verification/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`

	async getEmployees(params) {
		console.log('params', params);
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, { params });
	}

	async activeEmployeesDownload(params) {
		return Axios.request({
			url: `${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/download?status=${params.status}&pageNumber=${params.pageNumber}`,
			method: 'GET',
			responseType: 'blob',
		});
	}

	async getEmployeeStatusCount() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/statuscount`);
	}

	async getTransactionHistoryApi() {
		return Axios.get(`${this.transBaseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`);
	}

	async getEmployeeById(empId) {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/` + empId);
	}
	async getPendingRequest() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/pendingrequest`);
	}

	async getCardStatus(params) {
		return Axios.get(
			`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/cardstatus?pageNumber=${(params && params.pageNumber) || 1}&pageSize=${(params && params.pageSize) || 20
			}`
		);
	}

	async getFilterLookupData(params) {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/filter-lookup-data`, { params });
	}
	async createEmployee(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, body);
	}

	async getEmployeStatusAPI(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/requeststatus`, body);
	}

	async createEmployees(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}`, body);
	}
	async createEmployeesNew(body) {
		return Axios.put(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/bulk-update?userName=${body}`, body);
	}
	async updateEmployee(body, empId) {
		return Axios.patch(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/profile`, body);
	}
	async updateEmployeeAccountDetails(body, empId) {
		console.log('fhvb', body);
		return Axios.patch(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/bank`, body);
	}
	async updateWPS(body, empId) {
		return Axios.patch(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/wps`, body);
	}
	async updateEmpDocument(body, empId) {
		return Axios.patch(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/document`, body);
	}
	async updateBank(body, empId) {
		return Axios.patch(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/bank`, body);
	}
	async updateDisputeDetails(body, empId) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/atmdispute`, body);
	}
	async requestForStatement(body, empId) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/statement`, body);
	}
	async replacementCard(body, empId) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${empId}/replacementcard`, body);
	}
	async deleteEmployees(body, DeleteEmployeeType = 'Immediately') {
		const access_token = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)).access_token;
		// config.headers.Authorization = `Bearer ${access_token}`;
		fetch(
			`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/EmployeeCode?DeleteEmployeeType=${DeleteEmployeeType ? DeleteEmployeeType : 'Immediately'
			}`,
			{
				method: 'DELETE',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Content-Type': 'application/json-patch+json',
				},
			}
		);
		// return Axios.delete(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/EmployeeCode`, { data: body });
	}
	async deleteEmployeesBulk(body, DeleteEmployeeType = 'Immediately') {
		const access_token = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)).access_token;
		// config.headers.Authorization = `Bearer ${access_token}`;
		return fetch(
			`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/EmployeeCode?DeleteEmployeeType=${DeleteEmployeeType ? DeleteEmployeeType : 'Immediately'
			}`,
			{
				method: 'DELETE',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Content-Type': 'application/json-patch+json',
				},
			}
		);
		// return Axios.delete(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/EmployeeCode`, { data: body });
	}
	async deleteManageEmployees(body, DeleteEmployeeType = 'Immediately') {
		const access_token = JSON.parse(localStorage.getItem(APP_VARIABLES.ACCESS_TOKEN)).access_token;
		// config.headers.Authorization = `Bearer ${access_token}`;
		return fetch(
			`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/EmployeeCode?DeleteEmployeeType=${DeleteEmployeeType ? DeleteEmployeeType : 'Immediately'
			}`,
			{
				method: 'DELETE',
				body: JSON.stringify(body),
				headers: {
					Authorization: `Bearer ${access_token}`,
					'Content-Type': 'application/json-patch+json',
				},
			}
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.status);
				else return response.json();
			})
			.then((data) => {
				return data;
			});
	}
	async getEmployeesForDelete(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/deleteupload`, body);
	}
	async getRequestDetails(body, { pageNumber, pageSize, searchFilter = '' }) {
		return Axios.post(
			`${this.baseUrl}/${getLocalvariable(
				APP_VARIABLES.CORPORATE_ID
			)}/requestdetails?searchFilter=${searchFilter}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
			body
		);
	}
	async reviewPendingRequest(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/review`, body);
	}
	async getDashboardEmployeeCount() {
		return Axios.get(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/dashboard/employeecount`);
	}
	async getEmployeesHistory(employeeID) {
		return Axios.get(`${this.empSalBaseUrl}/EmployeeSalaries/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/${employeeID}`);
	}
	async getInvoiceDetails() {
		return Axios.get(`${this.empSalBaseUrl}/Invoice/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/detail`);
	}
	async uploadEmployeeData(body) {
		return Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/upload`, body);
	}

	validateUploadEmployeeSpreadSheet = (body, params) =>
		Axios.post(`${this.baseUrl}/${getLocalvariable(APP_VARIABLES.CORPORATE_ID)}/autofix-employee-file-error`, body, { params });

	// Employee verifications feature
	evaluateEmployeeList = (body) => {
		return Axios.post(`${this.employeeVerificationUrl}/reevaluate`, body)
	}
}

export default EmployeeService;

import React from 'react';
import { Row, Col } from 'antd';
import EdenredHeader from '@pages/main-layout/components/main-header';
import ImageComponent from '@sharedComponent/image-component';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { INVITE_TEAMS_CONSTANTS } from '@constants/app-constants';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import ClevertapReact from 'src/utils/clever-tap';
import { focus_state_icons } from 'src/assets/icons/focus_state';
import { icons } from 'src/assets/icons';
import './new-invite-team-member.scss';

const InviteTeamMemberPresentational = ({
	newDisable,
	handleClick,
	handleSelect,
	isSelect,
	getEmployeesNew,
	hangleDropChange,
	handleNewAdmin,
	existing,
	exDisable,
	newAdmin,
	isEmployeesLoading,
	userLoading,
	loading,
}) => {
	const newAdminSelect = [
		{
			label: 'Name',
			value: 'name',
			length: 50,
			datas: newAdmin?.name.replace(/[^a-zA-Z0-9 ]/g, ''),
		},
		{
			label: 'Username',
			value: 'username',
			length: 20,
			datas: newAdmin?.username,
		},
		{
			label: 'Email Address',
			value: 'email',
			length: '',
			datas: newAdmin?.email,
		},
	];
	const selectAdmin = [
		{
			name: 'Select Existing Employee',
			desc: 'Select the employee you would like to assign new role and permissions to.',
			value: 'existing',
		},
		{
			name: 'Add New Admin',
			desc: 'This will not create an employee record for them.',
			value: 'new',
		},
	];
	return (
		<>
			<EdenredHeader />
			<Row className="invite-team-memeber">
				<Col xs={24} sm={24} md={24} xl={24} lg={24} className="new_desgin_main">
					<div className="new_design_desc">
						<p className="invite-team-title">{INVITE_TEAMS_CONSTANTS.TITLE}</p>
						<p className="invite-team-desc">{INVITE_TEAMS_CONSTANTS.DESCRIPTION}</p>
					</div>
					<div className="new_desgin_button">
						<EdenredButtons
							className="new_desgin_cancel"
							btnType="secondary"
							leftIcon={<ImageComponent src={focus_state_icons.InviteCancelButton} />}
							onClick={() => handleClick(INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON, isSelect)}>
							{INVITE_TEAMS_CONSTANTS.CANCEL_BUTTON}
						</EdenredButtons>
						<EdenredButtons
							loading={userLoading || loading}
							btnType="primary"
							disabled={exDisable && newDisable}
							rightIcon={
								<ImageComponent src={exDisable && newDisable ? focus_state_icons.disabledRightArrow : focus_state_icons.RightArrow} />
							}
							onClick={() => {
								ClevertapReact.event('Next_Permissions', null);
								ClevertapReact.event('Next_InviteTeamMember', null);
								handleClick(INVITE_TEAMS_CONSTANTS.NEXT_BUTTON, isSelect);
							}}>
							{INVITE_TEAMS_CONSTANTS.NEXT_BUTTON}
						</EdenredButtons>
					</div>
				</Col>
				<Col xs={24} sm={24} md={24} xl={24} lg={24} className="invite-team-employee">
					{selectAdmin?.map((data) => (
						<div className="employee-select" onClick={() => handleSelect(data?.value)}>
							<Checkbox
								labelClassName="new-design-roles"
								tabIndex={3}
								borderColor="#292D32"
								borderRadius={4}
								borderWidth={1}
								size={16}
								icon={
									<span className="new_admin_check_box">
										<img src={icons.not_focus.tick} alt="check" />
									</span>
								}
								checked={isSelect?.[data?.value]}
								labelPadding="0px"
								label={
									<div>
										<p className={'employee-selected-text'}>{data?.name}</p>
										<p className={'employee-select-text'}>{data?.desc}</p>
									</div>
								}
							/>
						</div>
					))}
				</Col>
				{isSelect?.existing && (
					<>
						<Col xs={24} sm={24} md={24} xl={24} lg={24} className="invite-item-field">
							<Col xl={12} lg={12} md={12} sm={24} xs={24} className="payroll-setting-dropdown">
								<MultiSelect
									{...{
										searchHolder: 'Search',
										placeholder: 'Select Employees',
										Option: getEmployeesNew?.employees,
										value: existing?.name,
										onChange: (value) => hangleDropChange(value, 'name'),
										dropDownValue: 'employeeName',
										dropDownSubValue: 'employeeCode',
										showIcon: false,
									}}
								/>
							</Col>
							{existing?.name ? (
								<Col xl={12} lg={12} md={12} sm={24} xs={24} className="invite-team-email">
									<Inputbox
										type="text"
										label="Email Address"
										placeholder="Email Address"
										value={existing?.email}
										disabled={existing?.checkEmail}
										onChange={(event) => hangleDropChange(event.target.value, 'email')}
									/>
								</Col>
							) : (
								''
							)}
						</Col>
					</>
				)}
				{isSelect?.new && (
					<>
						<Col xs={24} sm={24} md={24} xl={24} lg={24} className="invite-item-field-new">
							{newAdminSelect?.map((data) => (
								<Col xs={16} sm={16} md={13} xl={11} lg={12} className="invite-team-new">
									<Inputbox
										label={data?.label}
										placeholder={data?.label}
										maxlength={data?.length}
										value={data?.datas}
										onChange={(event) => handleNewAdmin(event.target.value, data?.value)}
									/>
								</Col>
							))}
						</Col>
					</>
				)}
			</Row>
		</>
	);
};

export default InviteTeamMemberPresentational;

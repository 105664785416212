import React, { useEffect, useRef, useState } from 'react';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import Checkbox from '@pages/DesignSystem/Checkbox/Checkbox';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import Tooltip from '@pages/DesignSystem/Tool-Tip';
import { OTP_SOURCE_TYPE } from '@constants/app-constants';
import { useDispatch, useSelector } from 'react-redux';
import mfaActions from '../mfaActions';
import { MFA_CONSTANTS } from '../constants';

export const OtpValidation = ({ userEnteredNumber, setAccountLock, setMfaVerified, setResendOtp }) => {
	const [otp, setOtp] = useState(Array(6).fill(''));
	const [validation, setValidation] = useState(false);
	const [rememberDevice, setRememberDevice] = useState(false);
	const [attempted, setAttempted] = useState(0);
	const [seconds, setSeconds] = useState(30);
	const inputRefs = useRef(Array(6).fill(null));
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		const { status, remainingAttemptsCount } = auth.otp || {};
		if (status) {
			if (status === MFA_CONSTANTS.otpStatusVerify.WRONG_OTP) {
				if (remainingAttemptsCount) {
					setAttempted(remainingAttemptsCount);
				}
				setValidation(true);
			} else if (status === MFA_CONSTANTS.otpStatusVerify.LOCKED) {
				setValidation(false);
				setAccountLock(true);
			} else if (status === MFA_CONSTANTS.otpStatusVerify.VERIFIED) {
				setValidation(false);
				setAccountLock(false);
				setMfaVerified(true);
			}
		}
	}, [auth.otp]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds]);
	useEffect(() => {
		inputRefs.current = inputRefs.current.map((ref, index) => ref || createRef(index));
	}, []);

	// To check whether the mfa mode is SETUP
	const isSetupMode = () => {
		if (auth.mfa.mode === MFA_CONSTANTS.mfa.SETUP) {
			return true;
		}
		return false;
	};

	const createRef = (index) => {
		inputRefs.current[index] = React.createRef();
		return inputRefs.current[index];
	};

	const handleInput = (e, index) => {
		const value = e.target?.value;
		if (!isNaN(value)) {
			setOtp([...otp.slice(0, index), value, ...otp.slice(index + 1)]);
			if (value && inputRefs.current[index + 1]) {
				inputRefs.current[index + 1].current.focus();
			}
			if (value && !inputRefs.current[index + 1]) {
				inputRefs.current[index].current.blur();
			}
			// Move focus to the previous input if the current input is empty and backspace is pressed
			if (value === '') {
				// Using backsapce key to remove input
				if (e.nativeEvent.inputType === 'deleteContentBackward' && inputRefs.current[index - 1]) {
					inputRefs.current[index - 1].current.focus();
					// Using deletekey to remove input
				} else if (e.nativeEvent.inputType === 'deleteContentForward' && inputRefs.current[index + 1]) {
					inputRefs.current[index + 1].current.focus();
				}
				setValidation(false);
			}
		}
	};
	const handlePaste = (e) => {
		if (!isNaN(e.clipboardData.getData('text'))) {
			setOtp(e.clipboardData.getData('text').substring(0, 6).split(''));
		}
	};

	const userPhoneNumber = () => {
		if (userEnteredNumber.startsWith(MFA_CONSTANTS.countryCode) || userEnteredNumber.startsWith('00971')) {
			return userEnteredNumber;
		}
		return `${MFA_CONSTANTS.countryCode}${userEnteredNumber}`;
	};

	const handleVerifyOtp = () => {
		const body = {
			otp: otp.join(''),
			type: OTP_SOURCE_TYPE.sms,
			rememberMe: rememberDevice,
		};
		if (isSetupMode()) {
			body.phoneNumber = userPhoneNumber(); // If the mfa mode is setup then we send user's phone number in body
		}
		dispatch(mfaActions.creators.verifyMfa(body));
	};

	const handleResendOtp = () => {
		const body = {
			reason: MFA_CONSTANTS.otpReason.mfa,
		};
		if (isSetupMode()) {
			body.phoneNumber = userPhoneNumber(); // If the mfa mode is setup then we send user's phone number in body
		}
		dispatch(mfaActions.creators.sendOtp(body));
		setOtp(new Array(6).fill(''));
		setValidation(false);
		setSeconds(30);
		setResendOtp(true);
	};

	const validOTP = () => {
		if (otp.join('').length === 6 && !validation) {
			return true;
		}
		return false;
	};

	function encryptMobileNumber(number) {
		let firstThreeDigits = '';

		if (number?.startsWith('00971')) {
			firstThreeDigits = number?.slice(2, 5);
		} else if (number?.startsWith('971')) {
			firstThreeDigits = number?.slice(0, 3);
		} else {
			firstThreeDigits = number;
		}

		const middleDigits = '*'.repeat(7); // Replace middle digits with asterisks
		const lastTwoDigits = number?.slice(-2);

		return `+${firstThreeDigits}${middleDigits}${lastTwoDigits}`;
	}

	return (
		<div className="otp-container">
			<div className="otp-text-wrapper">
				<div className="otp-title">
					<ETypo h3 bold>
						Enter the OTP to Verify
					</ETypo>
					<ETypo h3 bold>
						Your Account
					</ETypo>
				</div>
				<div className="otp-msg-wrapper">
					<ETypo className="otp-msg" b1 light>
						The OTP has been sent to {encryptMobileNumber(userEnteredNumber)}
					</ETypo>
					<Tooltip
						direction="up"
						className="otp-info-icon"
						content={<span className="tooltip-text">You can update your Number from the Profile section</span>}>
						<img src={not_focus_icons.infoLight} alt="info-icon" />
					</Tooltip>
				</div>
			</div>
			<div className="otp-input-container">
				<div className="otp-input-wrapper">
					<div className="otp-input">
						{otp.map((digit, index) => (
							<input
								className={`input-field ${digit !== '' ? 'input-field-value' : 'border'} ${validation ? 'error-input' : ''}`}
								key={index}
								type="text"
								autoComplete="off"
								maxLength="1"
								name={`otp${index}`}
								value={digit}
								autoFocus={index === 0}
								onChange={(e) => handleInput(e, index)}
								onPaste={handlePaste}
								ref={inputRefs.current[index]}
							/>
						))}
					</div>
					{validation && (
						<ETypo b3 className="error-msg">
							{`Incorrect OTP! ${attempted} attempts remaining`}
						</ETypo>
					)}
				</div>
				<div className="remeber-device-checkbox">
					<Checkbox labelPadding="10px 10px 10px 0px" onChange={() => setRememberDevice((prev) => !prev)} checked={rememberDevice} />
					<ETypo b1 light>
						Remember device for 30 Days
					</ETypo>
				</div>
			</div>
			<div className="otp-validation">
				<ETypo b1 onClick={seconds === 0 ? handleResendOtp : undefined}>
					<span className={seconds === 0 ? 'resend-otp-text' : ''}>Resend OTP</span>&nbsp;
					{seconds > 0 && <span>{`in ${seconds}s`}</span>}
				</ETypo>
				<span className="verify-btn">
					<EdenredButtons
						loading={auth?.otp?.inProgress}
						children={'Verify OTP'}
						btnType={validOTP() ? 'primary' : 'secondary'}
						className={validOTP() ? '' : 'verify-button'}
						onClick={validOTP() ? handleVerifyOtp : undefined}
					/>
				</span>
			</div>
		</div>
	);
};

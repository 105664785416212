import React, { useEffect, useMemo, useRef, useState } from 'react';
import Chips from '../Chips/index';
import { icons } from '@assets/icons/index';
import ImageComponent from '@sharedComponent/image-component';
import Action from '@sharedComponent/app-action-reducer/action';
import { images } from 'src/assets/img';
import './sidenavbar.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_VARIABLES, PENDING_ALERTS_CLAIMS, PERMISSIONS, SETUP_REDIRECT } from '@constants/app-constants';
import userManager from 'src/config/user-manager';
import { arrayObjToArray, formatMenus, checkHrSubscription, checkWithHRClaims, checkAnyClaims, syncLocalStorage } from '@helpers';
import { useDispatch, useSelector } from 'react-redux';
import useSideEffects from './hooks/useSideEffects';
import useMenus from './hooks/useMenus';
// import { HrclaimsArray, verifierClaimsArr, HrClaims } from '@constants/app-constants';
import SetupNavbarStatus from '@pages/main-layout/components/setup-navbar-status';
import ClevertapReact from 'src/utils/clever-tap';
import { mergeAllClaims } from 'src/utils/claims';
import { LOCAL_STORAGE_KEYS } from '@constants/localStorage';

function useOutsideAlerter(ref, isToggledToMin, setMainmenu) {
	const _isToggledToMin = useRef(isToggledToMin);

	useEffect(() => {
		_isToggledToMin.current = isToggledToMin;
	}, [isToggledToMin]);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				if (_isToggledToMin.current) {
					setMainmenu((prevState) => {
						const result = prevState?.map((item, index) => {
							return { ...item, isVisible: false };
						});
						return result || [];
					});
				}
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

const SideNavBar = ({ removeUser, currentUser }) => {
	const [mainmenu, setMainmenu] = useState([]);
	const [navbar, setNavbar] = useState(false);
	const [payrollNewNavBarState, setPayrollNewNavBarState] = useState(false); //this use state is used to handle the navbar while the user enter to payroll-plus
	const [size, setSize] = useState(false);
	const approveReqCount = useSelector((state) => state?.sharedStates?.PendingStatusDetail?.pendingRequest);
	const { invoiceCorporateList } = useSelector((state) => state?.sharedStates);
	const role = (currentUser && currentUser?.roles && currentUser?.roles) || null;
	const emp_Id = useSelector((state) => state?.sharedStates?.currentUser?.user?.employeeCode);
	const profileImagePath = useSelector((state) => state?.sharedStates?.currentUser?.user?.profileImagePath);
	const profileImg = useSelector((state) => state?.sharedStates?.profileImg);
	const profileImgLoading = useSelector((state) => state?.sharedStates?.profileImgLoading);
	const isOnlyEmployeeExits = useSelector((state) => state?.sharedStates?.isOnlyEmployeeExits) || false;
	const navBarClose = useSelector((state) => state?.sharedStates?.navBarClose);
	const userName = currentUser?.user?.userName;
	const location = useLocation();
	const history = useHistory();
	const allClaims = useMemo(() => mergeAllClaims(currentUser), [currentUser]);
	const { menus } = useMenus();
	const isToggledToMin = navbar || payrollNewNavBarState || size;
	useSideEffects({ setMainmenu, menus, setSize, location, mainmenu });
	const dispatch = useDispatch();

	const navBarRef = useRef();
	useOutsideAlerter(navBarRef, isToggledToMin, setMainmenu);

	useEffect(() => {
		if (profileImagePath) {
			dispatch(Action.creators.getProfileImageStart(profileImagePath));
		}
	}, [profileImagePath]);
	const navBarStatusForPages = [
		'/payroll-plus',
		'/uploadSpreadsheetPlus',
		'/hr-features/allowance',
		'/hr-features/addition',
		'/hr-features/deduction',
		'/uploadSpreadsheetSetup',
		'/payroll-plus/preview-uploaded-document-data',
		'/run-payroll',
	];
	useEffect(() => {
		if (navBarStatusForPages.some((pageName) => pageName === location?.pathname)) {
			setPayrollNewNavBarState(true);
		} else {
			setPayrollNewNavBarState(false);
		}
	}, [location?.pathname]);
	const onMainMenuClick = (menuKey) => {
		if (menuKey !== 'logout') {
			let pathToPush = null;
			let cleverTapValue = null;
			const head = mainmenu?.map((item) => {
				if (item.key === menuKey) {
					pathToPush = typeof item.path === 'function' ? item.path(item.subMenus) : item.path;
					return {
						...item,
						isSelect: !item.isSelect,
						isVisible: !item.isVisible,
						subMenus: item.subMenus
							? item.subMenus.map((subMenu, index) => {
								if (index === 0) {
									return {
										...subMenu,
										isSelect: true,
									};
								} else {
									return { ...subMenu, isSelect: false };
								}
							})
							: [],
					};
				}
				return { ...item, isSelect: false };
			});
			setMainmenu(head || []);
			if (typeof pathToPush === 'string') {
				history.push({ pathname: pathToPush });
			}
			if (menuKey === 'features') {
				ClevertapReact.event('navbar_newfeatures');
			}
		} else {
			removeUser();
			localStorage.removeItem(APP_VARIABLES.USER_DETAILS);
			userManager.signoutRedirect({ id_token_hint: localStorage.getItem(APP_VARIABLES.ID_TOKEN) });
			userManager.removeUser(); // removes the user data from sessionStorage
			localStorage.removeItem(APP_VARIABLES.ACCESS_TOKEN);
			localStorage.removeItem(APP_VARIABLES.CORPORATE_ID);
			localStorage.removeItem(APP_VARIABLES.CORPORATE_NAME);
			localStorage.removeItem('isRedirectNeeded');
			localStorage.removeItem('isRedirectVerifierNeeded');
			localStorage.removeItem('amount');
			localStorage.removeItem('transferFundsPopupVisible');
			localStorage.removeItem('showUnemploymentBanner');
			localStorage.removeItem(SETUP_REDIRECT.SETUP_REDIRECT_TO);
			// removing the iloe banner keys
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.dashboardRenewalBanner)
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.beforeRenewal)
			syncLocalStorage.removeItem(LOCAL_STORAGE_KEYS.iloe.afterRenewal)
		}
	};

	const onSubMenuClick = (menuKey) => {
		let pathToPush = null;
		let cleverTapValue = null;
		const submenus = mainmenu?.map((item) => {
			let menusIsSelected = false;
			return {
				...item,
				subMenus: item?.subMenus?.map((items) => {
					if (items.key === menuKey) {
						pathToPush = typeof items.path === 'function' ? items.path() : items.path;
						menusIsSelected = true;
						cleverTapValue = items?.cleverTapValue;
						return { ...items, isSelect: true };
					}
					return { ...items, isSelect: false };
				}),
				isSelect: menusIsSelected,
			};
		});
		setMainmenu(submenus);
		if (typeof pathToPush === 'string') {
			history.push({ pathname: pathToPush });
		}
		if (typeof cleverTapValue === 'string') {
			ClevertapReact.event(cleverTapValue, null);
		}
	};
	const { showInvoiceMenuFlag } = useSelector((state) => state?.sharedStates);

	const menuFilter = (array, role) => {
		return array?.filter((items) => {
			/**
			 * Invoice menu should show only particular corporates, which includes of invoiceMenuToShow Array.
			 * showInvoiceMenuFlag should come from backend api.
			 */
			// if (items.key === 'invoice') {
			// 	return items.isnavigation === role && invoiceCorporateList.includes(localStorage.getItem('cid')) && showInvoiceMenuFlag;
			// } else {
			// 	return items.isnavigation === role;
			// }
			if (items.key === 'invoice') {
				return items.isnavigation === role && showInvoiceMenuFlag;
			} else {
				return items.isnavigation === role;
			}
		});
	};
	const payrollMenuItems = menuFilter(mainmenu, 'payroll');
	const hrMenuItems = menuFilter(mainmenu, 'hr');
	const defaultMenuItems = menuFilter(mainmenu, 'default');
	const bottomMenuItems = menuFilter(mainmenu, 'bottom');

	const checkSubscription = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const status = useSelector((state) => state?.sharedStates?.setupStatus);
	const loadingRefresh = useSelector((state) => state?.sharedStates?.pendingStatusLoading);

	const isClaimsExits = (roles, claim1, claim2) => {
		return (
			(!currentUser?.payrollClaims?.length && roles?.length === 1 && checkAnyClaims(roles, [claim1, claim2])) ||
			(!currentUser?.payrollClaims?.length && roles?.length === 2 && checkWithHRClaims(roles, claim1) && checkWithHRClaims(roles, claim2))
		);
	};
	const isSetupVisible =
		!isOnlyEmployeeExits &&
		status === 'notCompleted' &&
		checkSubscription &&
		!isClaimsExits(currentUser?.hrClaims, PERMISSIONS.MANAGE_LEAVES, PERMISSIONS.MANAGE_ANNOUNCEMENTS);

	const shouldShowVerifyApproval = () => {
		const shouldShow = allClaims.some((userClaim) => {
			return PENDING_ALERTS_CLAIMS.indexOf(userClaim) >= 0;
		});
		return shouldShow;
	};
	return (
		<section className={navbar || payrollNewNavBarState || size ? 'responsive' : 'navbar'} ref={navBarRef}>
			<div className="side-nav-outer">
				<div className="side-nav-inner">
					<div className="side-nav-top">
						<div className="profile">
							<div
								className="profile-image"
								onClick={() =>
									history.push({ pathname: '/dashboard/profile', state: { tableData: { employeeId: emp_Id }, mainProfile: true } })
								}>
								<ImageComponent
									style={{ height: '30px', width: '30px', borderRadius: '10%', objectFit: 'cover', backgroundColor: '#ddd' }}
									src={profileImg ? profileImg : icons.focus_state.User}
									className={`user ${profileImgLoading ? 'imgLoading' : ''}`}
								/>
							</div>
							<div
								className="profile-content"
								onClick={() =>
									history.push({ pathname: '/dashboard/profile', state: { tableData: { employeeId: emp_Id }, mainProfile: true } })
								}>
								<p title={userName ? userName : ''} className="h5 side-nav-user-name">
									{userName ? userName : ''}
								</p>
								<p className="verifier">
									{isOnlyEmployeeExits
										? 'Account'
										: arrayObjToArray(role, 'name')?.includes('SuperAdmin')
											? 'Super Admin'
											: 'Admin'}
								</p>
							</div>

							<div className="profile-icon" style={{ paddingLeft: '10px' }}>
								<ImageComponent
									src={images.menu}
									onClick={() => {
										if (navBarStatusForPages.some((pageName) => pageName === location?.pathname)) {
											setPayrollNewNavBarState(!payrollNewNavBarState);
										} else {
											setNavbar(!navbar);
										}
									}}
									className="navicon"
								/>
							</div>
						</div>
						{shouldShowVerifyApproval() === true && (
							<div className="pending-outer">
								<div
									className="pending"
									onClick={() => history.push({ pathname: '/dashboard/pending-alerts', state: { approveReqCount, role } })}>
									<div className="image">
										<div className="pending-image">
											<ImageComponent src={images.pending} />
										</div>
									</div>
									<div className="pending-content">
										<p className="alert">
											{approveReqCount && approveReqCount?.totalRequest ? approveReqCount?.totalRequest : 0} Pending Alerts
										</p>
										<p className="details">View Details</p>
									</div>
									<div className="pending-arrow">
										<ImageComponent src={images.pendingIcon} className="pending-arrow-image" />
									</div>
								</div>
							</div>
						)}
					</div>
					<div className="make_scroll">
						<div className="side-nav-items">
							{payrollMenuItems?.map((item, index) => (
								<GetMenuItem
									onHover={navbar || payrollNewNavBarState ? true : false}
									key={index}
									menus={item}
									onMainMenuClick={onMainMenuClick}
									mainmenu={mainmenu}
									onSubMenuClick={onSubMenuClick}
								/>
							))}
						</div>
						<div className="side-nav-items">
							{hrMenuItems?.map((item, index) => (
								<GetMenuItem
									onHover={navbar || payrollNewNavBarState ? true : false}
									key={index}
									menus={item}
									onMainMenuClick={onMainMenuClick}
									mainmenu={mainmenu}
									onSubMenuClick={onSubMenuClick}
								/>
							))}
						</div>
						<div className="side-nav-items">
							{defaultMenuItems?.map((item, index) => (
								<GetMenuItem
									onHover={navbar || payrollNewNavBarState ? true : false}
									key={index}
									menus={item}
									onMainMenuClick={onMainMenuClick}
									mainmenu={mainmenu}
									onSubMenuClick={onSubMenuClick}
								/>
							))}
						</div>
						{/* {isSetupVisible && (
							<div className="side-nav-items">
								<SetupNavbarStatus />
							</div>
						)} */}
					</div>
					<div className="side-nav-items">
						{bottomMenuItems?.map((item, index) => (
							<GetMenuItem
								onHover={navbar || payrollNewNavBarState ? true : false}
								key={index}
								menus={item}
								onMainMenuClick={onMainMenuClick}
								mainmenu={mainmenu}
								onSubMenuClick={onSubMenuClick}
							/>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

export default SideNavBar;

const GetMenuItem = ({ menus, onMainMenuClick, onSubMenuClick, onHover }) => {
	const location = useLocation();
	const employeesSubmenuPath = [
		'/employees/manage_employee',
		'/employees/card/order/status',
		'/employees/approve_employee_request',
		'/employees/viewemployee',
	];
	const payrollSubmenuPath = [
		'/payroll-plus',
		'/uploadSpreadsheetPlus',
		'/hr-features/allowance',
		'/hr-features/addition',
		'/hr-features/deduction',
		'/uploadSpreadsheetSetup',
		'/payroll-plus/preview-uploaded-document-data',
		'/run-payroll',
		'/salary',
	];
	const companySubmenuPath = ['/company/company-setting', '/manage_team', '/company/information'];
	const leavesSubmenuPath = ['/approval-workflow-leave'];
	const menuActiveState = (label, path) => {
		if (path === location?.pathname) {
			return true;
		} else if (
			label === 'Payroll' &&
			(location?.pathname.split('/')[1] === 'payroll' || payrollSubmenuPath.some((path) => path === location?.pathname))
		) {
			return true;
		} else if (label === 'Employees' && location?.pathname.split('/')[1] === 'employees') {
			return true;
		} else if (
			label === 'Leaves' &&
			(location?.pathname.split('/')[1] === 'leaves' || leavesSubmenuPath.some((path) => path === location?.pathname))
		) {
			return true;
		} else if (
			label === 'Company' &&
			(location?.pathname.split('/')[1] === 'company' || companySubmenuPath.some((path) => path === location?.pathname))
		) {
			return true;
		} else if (label === 'Unemployment Insurance' && location?.pathname.split('/')[1] === 'un-employment-insurance') {
			return true;
		} else {
			return false;
		}
	};

	if (menus?.type === 'common') {
		// return (
		// 	<div className="experience">
		// 		<p className="experience-content">Experience better HR and Payroll</p>
		// 		<button>Know more </button>
		// 	</div>
		// );
		return <></>;
	} else {
		return (
			<div className={`noresponsive ${onHover ? 'hover' : 'hide-hover'}`}>
				<div
					className={` fs-nav-out ${menuActiveState(menus?.label, menus?.path) ? 'fs-nav-out-active' : ''}`}
					onClick={() => onMainMenuClick(menus?.key)}>
					<div className={`fs-nav-item ${menuActiveState(menus?.label, menus?.path) && 'active'} `}>
						<ImageComponent
							src={menuActiveState(menus?.label, menus?.path) ? menus.activeIcon : menus.icon}
							className={`fs-icon ${menuActiveState(menus?.label, menus?.path) ? 'small' : 'big'} `}
						/>
						<div className={menuActiveState(menus?.label, menus?.path) ? 'fs-nav-label-active' : 'fs-nav-label-out'}>{menus.label} </div>
						<div className="icn-chips">
							{menus?.chip && (
								<Chips color="#EEDCBA">
									<p className="text">New</p>
								</Chips>
							)}
						</div>
					</div>
					<div className="fs-nav-arrow">
						{menus?.subMenus?.length > 0 && (
							<ImageComponent src={menus?.isVisible ? icons.not_focus.up : icons.not_focus.down} className="fs-sub-menu-arrow" />
						)}
					</div>
				</div>
				<div className="submenu" data-test="hyy">
					{menus?.subMenus?.length > 0 &&
						// menus?.isSelect &&
						menus?.isVisible &&
						menus?.subMenus?.map((item) => <Getsubmenu menus={item} onSubMenuClick={onSubMenuClick} />)}
				</div>
			</div>
		);
	}
};
const Getsubmenu = ({ menus, onSubMenuClick }) => {
	return (
		<div className={` fs-nav-submenu ${menus.isSelect ? 'fs-nav-submenu-active' : ''} `} onClick={() => onSubMenuClick(menus?.key)}>
			{/* <ImageComponent src={menus.icon} className="fs-icon" /> */}
			<p className="label">{menus.label} </p>
		</div>
	);
};

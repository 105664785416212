import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Inputbox from '@pages/DesignSystem/Input-Field';
import ETypo from '@pages/DesignSystem/Typo';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import ImageComponent from '@sharedComponent/image-component';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import mfaActions from '../mfaActions';
import { MFA_CONSTANTS } from '../constants';

const SetUpMfa = ({ setuserEnteredNumber }) => {
	const [mobileNumber, setmobileNumber] = useState('');
	const [validation, setValidation] = useState(false);
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	const sendingOTP = () => {
		if (mobileNumber.length !== 9) {
			setValidation(true);
			return;
		}
		const body = {
			reason: MFA_CONSTANTS.otpReason.mfa,
			phoneNumber: `${MFA_CONSTANTS.countryCode}${mobileNumber}`,
		};
		dispatch(mfaActions.creators.sendOtp(body));
		setuserEnteredNumber(`${MFA_CONSTANTS.countryCode}${mobileNumber}`);
		setValidation(false);
	};

	return (
		<div className="setup-mfa-container">
			<div className="setup-mfa-text">
				<div className="setup-mfa-title">
					<ETypo h3 bold>
						Set up Multi-Factor
					</ETypo>{' '}
					<br />
					<ETypo h3 bold>
						Authentication
					</ETypo>
				</div>
				<div className="setup-mfa-description">
					<ETypo className="setup-mfa-description-text" b2 medium>
						To verify your identity, please enter your mobile number.
					</ETypo>
					<br />
					<ETypo className="setup-mfa-description-text" b2 medium>
						The number entered will be saved for 2-step verification.
					</ETypo>
				</div>
			</div>
			<div className="setup-mfa-number-field-wrapper">
				<div className="setup-mfa-number-field">
					<Inputbox
						leftImage={<ImageComponent src={not_focus_icons.countryImage} className="input-text-countryIcon" alt="country-Icon" />}
						value={mobileNumber}
						onChange={(e) => setmobileNumber(e.target.value)}
						prefix={`+${MFA_CONSTANTS.countryCode}`}
						placeholder="Enter your mobile number"
						label="Mobile Number"
						className={`${validation ? 'error-input' : ''}`}
						type="number"
					/>
					{validation && (
						<ETypo b3 className="error-msg">
							Invalid Number
						</ETypo>
					)}
				</div>
				<div className="setup-mfa-otp-button-container" style={{ display: 'flex', justifyContent: 'end' }}>
					<EdenredButtons
						loading={auth?.otp?.loading}
						className="setup-mfa-otp-button"
						style={{ maxWidth: '103px' }}
						children="Send OTP"
						onClick={sendingOTP}
					/>
				</div>
			</div>
		</div>
	);
};

export default SetUpMfa;

import React from 'react';
import EdenredButton from '@sharedComponent/button';
import LandingModal from '@sharedComponent/landing-page-modal/landing-modal';
import EdenredTypo from '@sharedComponent/typography';
import Inputs from '@sharedComponent/text-field/index';
import './transferFundPop.scss';
import { setLocalvariable } from '@helpers';

const TransferFundPopUpPresentational = ({
	accNumber,
	showTransferCode,
	setShowTransferCode,
	createRakankAccDetails,
	errorMessage,
	postBankDetails,
	transferCode,
	isBankCardIsVisible,
	postLoader,
	initialAccNumber,
	setIsBankCardIsVisible,
}) => {
	function onClose(type) {
		if (type === 'Done' || type === 'Understood') {
			postBankDetails(type, accNumber);
		} else if (typeof setIsBankCardIsVisible === 'function') {
			setLocalvariable('transferFundsPopupVisible', false);
			setIsBankCardIsVisible(false);
		}
	}

	return (
		<LandingModal visible={isBankCardIsVisible} handleClose={() => onClose()} className="payroll-fund-transfer-popup">
			<div className="fund-transfer-section">
				<div className="account-number-payroll-transfund-card-section">
					<EdenredTypo className="card-title-style">{showTransferCode ? 'Transfer Code' : 'RAKBANK Account Number'}</EdenredTypo>

					<div className="account-number-holder">
						{showTransferCode ? (
							<EdenredTypo className="acct-number-style">{transferCode}</EdenredTypo>
						) : (
							<Inputs
								onChange={(e) => (e.target.value.length < 14 ? createRakankAccDetails(e.target.value) : null)}
								isloading={postLoader}
								placeholder="Enter RAKBANK Account Number"
								value={accNumber}
								type="Number"
								isError={errorMessage}
								errorMessage={'Enter 13 digit number'}
								className="reconc-account-input"
								edit={true}></Inputs>
						)}
					</div>
				</div>
				<div className="fund-transfer-info-section">
					{/* As per uzair's request made the text changes. */}
					<EdenredTypo className="info-title">Mandatory Transfer Code!</EdenredTypo>
					<EdenredTypo className="tranfer-fun-info-style">
						{showTransferCode
							? 'Enter this number as your reference on your business banking portal while sending your salary funds to us so we can automatically update your balance and process salaries.'
							: 'Your RAKBANK Account Number will be used as reference when you transfer your salary funds to us.'}
					</EdenredTypo>
					<EdenredTypo className="tranfer-fun-info-style2">
						{showTransferCode
							? '	Using a RAKBANK Corporate Account to send us salary funds? '
							: 'Don’t have a RAKBANK Corporate Account? We will generate a transfer code you can enter while sending us salary funds,'}
						<br></br>

						<EdenredTypo onClick={() => setShowTransferCode(!showTransferCode)} className="click-here-style">
							Click here
						</EdenredTypo>
					</EdenredTypo>
				</div>
				<div className="fund-transfer-button-container">
					<EdenredButton
						disabled={showTransferCode ? false : initialAccNumber == accNumber || errorMessage || accNumber.length === 0}
						onClick={(e) => (showTransferCode ? onClose('Understood') : onClose('Done'))}
						className="undertood-style">
						{showTransferCode ? 'Understood' : 'Done'}
					</EdenredButton>
				</div>
			</div>
		</LandingModal>
	);
};
export default TransferFundPopUpPresentational;

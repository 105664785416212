import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Col, Tooltip } from 'antd';
import { ClipLoader } from 'react-spinners';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { APP_VARIABLES, DEFAULT_TEXT } from '@constants/app-constants';
import { checkHrSubscription } from '@helpers';
import action from '@sharedComponent/app-action-reducer/action';
import SelectorsApp from '@sharedComponent/app-action-reducer/selectors';
import ImageComponent from '@sharedComponent/image-component';
import Action from '@sharedComponent/app-action-reducer/action';
import Chips from '@pages/DesignSystem/Chips';
import EmployeeService from 'src/services/employee-service';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { withReducer } from 'src/store/reducerLoader';
import PopupComponent from './popup-component';
import Selectors from '../selector';
import Actions from '../action';
import reducer from '../reducer';
import sagas from '../saga/index';
import ManageEmployeePresentational from './manage-employee-presentational';
import useClaimsBasedContents from '../../hooks/useClaimsBasedContents/useClaimsBasedContents';

const ManageEmployeeFunctional = (props) => {
	const [dataCountToShow, setDataCountToShow] = useState(10);
	const [employeeTableData, setEmployeeTableData] = useState([]);
	const [employetableSelect, setEmployeTableSelect] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [selectedEmpCode, setSelectedEmpCode] = useState([]);
	const [employetableNotSelect, setEmployeTableNotSelect] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState([]);
	const [selectedEmployeeLists, setSelectedEmployeeLists] = useState([]);
	const [activeTabs, setactiveTab] = useState('1');
	const activeTabRef = useRef(null);
	const { state } = useLocation();
	const [visible, setVisible] = useState(false);
	const [popupVisible, setpopupVisible] = useState(false);
	const [subscription, setSubscription] = useState(false);
	const [confirmationModelOpen, setConfirmationModelOpen] = useState(false);
	const [popupMessage, setPopupMessage] = useState({
		titleMessage: '',
		subMessage: '',
	});
	const [emp_Id, setEmp_Id] = useState();
	const [mailState, setMailState] = useState({
		mobileNumber: '',
		employeeCode: '',
		emailId: '',
		isEmail: '',
		employeeName: '',
		employeeId: '',
		portalInviteStatus: '',
	});
	const [cardloading, setcardloading] = useState(false);
	const [kycStatus, setKycStatus] = useState(false);
	let [selectedTab, setSelectedTab] = useState('1');
	let [totalFilterCount, setTotalFilterCount] = useState(0);
	let [enableFooter, setEnableFooter] = useState(false);
	let [enableCutOff, setEnableCutOff] = useState(false);
	const [isData, setIsData] = useState(true);
	const [activeAccountPagination, setactiveAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [creatingAccountPagination, setcreatingAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [verifyApprovalPagination, setverifyApprovalPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});
	const [deletedAccountPagination, setdeletedAccountPagination] = useState({
		pagination: {
			current: 1,
			pageSize: dataCountToShow,
		},
	});

	const { shouldRender: renderBasedOnClaims } = useClaimsBasedContents();
	const [accountType, setAccountType] = useState({
		c3appUser: '',
		accountType: '',
	});

	useEffect(() => {
		activeTabRef.current = activeTabs;
	}, [activeTabs]);

	useEffect(() => {
		handleTabChange(activeTabRef.current, {});

		if (activeTabRef.current === '1') {
			setactiveAccountPagination((prevState) => {
				return {
					...prevState,
					pagination: {
						...prevState.pagination,
						pageSize: dataCountToShow,
					},
				};
			});
		} else if (activeTabRef.current === '2') {
			setcreatingAccountPagination((prevState) => {
				return {
					...prevState,
					pagination: {
						...prevState.pagination,
						pageSize: dataCountToShow,
					},
				};
			});
		} else if (activeTabRef.current === '3') {
			setverifyApprovalPagination((prevState) => {
				return {
					...prevState,
					pagination: {
						...prevState.pagination,
						pageSize: dataCountToShow,
					},
				};
			});
		} else if (activeTabRef.current === '4') {
			setdeletedAccountPagination((prevState) => {
				return {
					...prevState,
					pagination: {
						...prevState.pagination,
						pageSize: dataCountToShow,
					},
				};
			});
		}
	}, [dataCountToShow]);

	const handleEmpToPortal = () => {
		mailState?.emailId && props.emailDataSaga(mailState);
		(!mailState?.isEmail || (mailState?.isEmail && mailState?.isEmail !== mailState?.emailId)) && props.updateEmailSaga(mailState);
	};
	const handleForCancel = () => {
		setMailState({
			...mailState,
			mobileNumber: '',
			employeeCode: '',
			emailId: '',
			isEmail: '',
			employeeName: '',
			employeeId: '',
			portalInviteStatus: '',
		});
		props.invitePortalPopup(false);
	};
	let userLocation = useSelector((state) => state?.sharedStates?.corporateIds);
	let address = Object?.assign(
		{},
		...(userLocation || [])?.filter((corporate) => corporate?.corporateId === localStorage.getItem(APP_VARIABLES.CORPORATE_ID))
	);
	const afterConfirmationOpenModal = () => {
		setConfirmationModelOpen(false);
	};
	const confirmationsRequest = async () => {
		setcardloading(true);
		try {
			let response = await employeeApi.replacementCard({ confirmation: 'yes' }, mailState?.employeeId);
			setcardloading(false);
			//if responce has some message show popup
			if (response && response?.status === 200) {
				// show normal popup
				setConfirmationModelOpen(false);
				history.push({ pathname: '/employees/replace_card_next_step', state: { ...mailState } });
			}
		} catch (err) {
			setcardloading(false);
			setpopupVisible(true);
			setPopupMessage({
				titleMessage: 'Something went wrong',
				subMessage: null,
			});
		}
	};
	let [employeeCount, setEmployeeCount] = useState({
		activeEmployeeCount: 0,
		creatingAccountNoCount: 0,
		deleteAccountCount: 0,
		pendingVerifierApprovalCount: 0,
	});

	let [isDataLoading, setIsDataLoading] = useState(false);
	let [filterData, setFilterData] = useState({
		accountTypes: [],
		bankNames: [],
		cardStatuses: [],
		emiratesIdStatuses: [],
		establishments: [],
		payrollChannels: [],
	});

	let [selectedFilterCount, setSelectedFilterCount] = useState({
		accountTypes: 0,
		bankNames: 0,
		cardStatuses: 0,
		emiratesIdStatuses: 0,
		establishments: 0,
		payrollChannels: 0,
	});

	let queryParamFieldMapping = {
		accountTypes: 'accountType',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannel',
	};
	let labelFieldMapping = {
		accountTypes: 'accountTypeName',
		bankNames: 'bankName',
		cardStatuses: 'cardStatus',
		emiratesIdStatuses: 'emiratesIdStatus',
		establishments: 'establishmentId',
		payrollChannels: 'payrollChannelName',
	};
	let history = useHistory();
	let employeeApi = new EmployeeService();
	const buttonRef = useRef(null);
	const getEmployeeStatusCountApiCalled = useRef(false);

	let currentUser = useSelector((state) => state?.sharedStates?.currentUser);
	useEffect(() => {
		setIsData(employeeTableData?.length <= 0);
	}, [employeeTableData]);
	useEffect(() => {
		let otherParams = {};
		if (state) {
			otherParams = {
				c3appUser: state?.type === 'C3Pay App Users' ? DEFAULT_TEXT?.YES : '',
				accountType: state?.type !== 'Total Employees' ? state?.type : '',
			};
			setAccountType({
				...accountType,
				c3appUser: state?.type === 'C3Pay App Users' ? DEFAULT_TEXT?.YES : '',
				accountType: state?.type !== 'Total Employees' ? state?.type : '',
			});
		}
		if (currentUser && currentUser?.roles && currentUser?.payrollClaims) {
			let role = currentUser.payrollClaims?.map((data) => data?.value);
			setEnableFooter(role?.includes('Manage.Employees'));
			setEnableCutOff(role?.includes('Manage.Employees'));
		}
		if (props?.history?.location?.state && props?.history?.location?.state?.filterData) {
			handleFilterChange(props?.history?.location?.state?.filterData);
			setFilterData({ ...props?.history?.location?.state?.filterData });
		} else {
			getEmployeeDetails('', otherParams);
			fetchFilterLookupData();
		}
	}, []);
	const onSearch = async (searchValue) => {
		let queryParams = {};
		let updatedSelectedFilterCount = { ...selectedFilterCount };
		Object.keys(filterData).forEach((key) => {
			let queryKey = queryParamFieldMapping[key];
			let selected = filterData[key]?.filter((data) => data?.isSelected);
			updatedSelectedFilterCount[key] = selected?.length;
			if (selected.length > 0) {
				queryParams[queryKey] = selected
					.reduce((acc, data) => {
						acc.push(data[labelFieldMapping[key]]);
						return acc;
					}, [])
					.join(',');
			}
		});
		let data = await { ...queryParams, search: searchValue, pageNumber: '' };
		await getEmployeeDetails(getStatusForTab(selectedTab), data);
	};

	//handle individual employee select in table
	const handleCheckBox = (employeeCode) => {
		let SelectedEmp = [];
		const newSelectEmp = selectedEmployeeLists?.map((data) => data?.employeeCode);
		if (newSelectEmp.indexOf(employeeCode) >= 0) {
			let updatedSelected = selectedEmployee?.filter((code) => code !== employeeCode);
			let updatedSelectedLists = selectedEmployeeLists?.filter((code) => code?.employeeCode !== employeeCode);
			setSelectedEmployee(updatedSelected);
			setSelectedEmployeeLists(updatedSelectedLists);
			setMobileViewData(employeeTableData, updatedSelected);
		} else {
			let updatedSelected = [...selectedEmployee];
			let updatedSelectedLists = [...selectedEmployeeLists];
			updatedSelected.push(employeeCode);
			let employee = employeeTableData
				?.map((data) => {
					return {
						...data,
						isEmpSelected: true,
						pageNumber: activeAccountPagination?.pagination?.current,
					};
				})
				?.filter((code) => code?.employeeCode === employeeCode);
			updatedSelectedLists.push(employee[0]);
			setMobileViewData(employeeTableData, updatedSelected);
			setSelectedEmployee([...updatedSelected]);
			setSelectedEmployeeLists([...updatedSelectedLists]);
		}
		SelectedEmp = employeeTableData.filter((employee) => {
			if (employeeCode === employee.employeeCode) {
				return employee;
			}
		});
		if (!selectedEmpCode.indexOf(SelectedEmp[0].employeeCode) >= 0) {
			let downloadSelected = selectedEmployees.concat(SelectedEmp[0]);
			let downloadSelectedCode = selectedEmpCode.concat(SelectedEmp[0]?.employeeCode);
			setSelectedEmployees(downloadSelected);
			setSelectedEmpCode(downloadSelectedCode);
		}
	};

	// view-employee-details
	const handlepassdata = (tab, val) => {
		let data = val;
		history.push({ pathname: '/employees/viewemployee', state: { selectedTab: tab, tableData: data, mainProfile: false } });
	};

	const isSubcribed = checkHrSubscription(JSON.parse(localStorage.getItem(APP_VARIABLES.PROD_SUBSCRIPTIONS)));
	const switchCard = (value, val1) => {
		switch (value) {
			case null:
				return (
					isSubcribed && (
						<Col className="emp_card" onClick={() => handleInvitePopup(value, val1)}>
							<p className="invite_to_portal">Invite To Portal</p>
						</Col>
					)
				);
			case 1:
				return (
					isSubcribed && (
						<Col className="emp_card_reissue" onClick={() => handleInvitePopup(value, val1)}>
							{val1?.isResentCardLoading ? (
								<ClipLoader size={13} color={'#484b52'} loading />
							) : (
								<p className="resend_invitation">Resend Invitation</p>
							)}
						</Col>
					)
				);
			case 3:
				return (
					<Col className="emp_card_replace" onClick={() => handleInvitePopup(value, val1)}>
						{val1?.isReplaceLoad ? <ClipLoader size={13} color={'#484b52'} loading /> : <p>Replace Card</p>}
					</Col>
				);
			default:
				break;
		}
	};
	const fetchEmployeesQueryParams = {
		status: 'ACTIVE',
		includeEmployeeCounts: true,
		pageSize: dataCountToShow,
		pageNumber: activeAccountPagination?.pagination?.current,
		payrollBlockCheck: false,
	};
	useEffect(() => {
		if (props.inviteCardStatus === 'Success') {
			let queryParams = fetchEmployeesQueryParams;
			fetchEmployees(queryParams);
			props.inviteCardStatusSaga('');
		}
	}, [props.inviteCardStatus]);

	const handleInvitePopup = (value, val1) => {
		setMailState({
			...mailState,
			mobileNumber: val1?.mobileNumber || '',
			employeeCode: val1?.employeeCode || '',
			emailId: val1?.emailId || '',
			isEmail: val1?.emailId || '',
			employeeName: val1?.employeeName || '',
			employeeId: val1?.employeeId || '',
			portalInviteStatus: val1?.portalInviteStatus,
		});
		if (isSubcribed) {
			if (val1?.portalInviteStatus === null && val1?.accountType !== 'C3Pay Cardholders') {
				props.invitePortalPopup(true);
			} else if ((val1?.portalInviteStatus === 1 || val1?.portalInviteStatus === 2) && val1?.accountType !== 'C3Pay Cardholders') {
				setEmployeeTableData(replaceCardLoading('isResentCardLoading', val1, true));
				props.emailDataSaga(val1);
			} else {
				setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, true));
				replaceCardRequesting(val1);
			}
		} else if (value === 3) {
			setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, true));
			replaceCardRequesting(val1);
		} else {
			setSubscription(true);
		}
	};

	const replaceCardLoading = (value, val1, loading) => {
		return employeeTableData?.map((data) => {
			if (data?.employeeId === val1?.employeeId) {
				return {
					...data,
					[value]: loading,
				};
			}
			return data;
		});
	};
	const replaceCardRequesting = async (val1) => {
		try {
			let response = await employeeApi.getEmployeeById(val1?.employeeId);
			if (response && response.data) {
				setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, false));
				if (!response?.data?.employee?.requestReplacementCardButtonEnabledState) {
					setpopupVisible(true);
					setPopupMessage({
						titleMessage: response?.data?.employee?.requestReplacementCardMessage,
						subMessage:
							'You already requested a card replacement to this employee. The card will be delivered to your office address soon.',
					});
				} else {
					setpopupVisible(false);
					setConfirmationModelOpen(true);
					response?.data?.employee?.kycStatus === true ? setKycStatus(true) : setKycStatus(false);
				}
			}
		} catch (err) {
			setEmployeeTableData(replaceCardLoading('isReplaceLoad', val1, false));
			setpopupVisible(false);
		}
	};
	let columns = [
		{
			sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
			key: 'employeeName',
			title: 'Name',
			dataIndex: 'employeeName',
			width: '11.7%',
			hidden: false,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				return (
					<Col
						className="name empName"
						style={{ textDecoration: 'underline', color: '#2B769C', textUnderlinePosition: 'under', cursor: 'pointer' }}
						onClick={(e) => handlepassdata('1', val1)}>
						{val}
					</Col>
				);
			},
		},
		{
			sorter: (a, b) => a.employeeCode - b.employeeCode,
			key: 'employeeCode',
			title: 'Employee ID',
			dataIndex: 'employeeCode',
			width: '12.5%',
			hidden: false,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				return <Col className="valid_detail">{val}</Col>;
			},
		},
		{
			sorter: (a, b) => a.designation - b.designation,
			key: 'designation',
			title: 'Designation',
			dataIndex: 'designation',
			width: '14%',
			hidden: !isSubcribed,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				return <Col className="valid_detail">{val}</Col>;
			},
		},
		{
			sorter: (a, b) => a.accountType - b.accountType,
			key: 'accountType',
			title: 'Status',
			dataIndex: 'accountType',
			width: '14%',
			hidden: !renderBasedOnClaims.accountTypeColumn,
			activeTab: true,
			sort: true,
			render: (val, val1) => {
				if (val === 'C3Pay Cardholders') {
					return (
						// C3Pay App User
						<Col className="valid_detail">
							<Chips color="#CCECE6">C3Pay Cardholders</Chips>
						</Col>
					);
				}
				if (val === 'Bank Accounts') {
					return (
						<Col className="valid_detail">
							<Chips color="#FFBECB">Bank Accounts</Chips>
						</Col>
					);
				} else {
					return (
						<Col className="valid_detail">
							<Chips color="#F7F8FA">{val}</Chips>
						</Col>
					);
				}
			},
		},
		{
			key: '',
			title: '',
			dataIndex: '',
			width: '10%',
			hidden: !renderBasedOnClaims.deleteCardColumn,
			activeTab: selectedTab === '1',
			sort: false,
			render: (val, val1) => {
				const Component = false ? Tooltip : Fragment;
				const datas =
					(val1?.portalInviteStatus === null || val1?.portalInviteStatus === 1 || val1?.portalInviteStatus === 2) &&
					val1.accountType !== 'C3Pay Cardholders'
						? val1?.portalInviteStatus !== 2
							? val1?.portalInviteStatus
							: 1
						: 3;
				return (
					<Col className="valid_detail_1	">
						<Component placement="top" title="The replacement card request is pending your approval" overlayClassName="toolTipOverlay">
							{switchCard(datas, val1)}
						</Component>
						<Col className="emp_delete" onClick={() => showModal(val, val1)} style={{ cursor: 'pointer' }}>
							<ImageComponent className="emp_trash_img" src={not_focus_icons?.Trash} />
						</Col>
					</Col>
				);
			},
		},
	]
		?.filter((item) => !item?.hidden)
		?.filter((data) => data?.activeTab);
	async function getEmployeeDetails(status, otherParams = {}) {
		if (!status) {
			status = getStatusForTab(selectedTab);
		}
		setIsDataLoading(true);
		let queryParams = {
			status: status,
			includeEmployeeCounts: true,
			pageSize: dataCountToShow,
			pageNumber: 1,
			payrollBlockCheck: false,
			...otherParams,
		};
		await fetchEmployees(queryParams);
		setIsDataLoading(false);
	}

	const [employeeListForDownload, setemployeeListForDownload] = useState({
		data: [],
		loading: false,
	});
	async function getEmployeeDetailsForDownload(status, otherParams = {}) {
		if (!status) {
			status = getStatusForTab(selectedTab);
		}
		let queryParams = {
			status: status,
			includeEmployeeCounts: true,
			pageSize: '',
			pageNumber: 1,
			...otherParams,
		};
		await fetchEmployeesForDownload(queryParams);
	}
	const [totalCount, settotalCount] = useState(0);
	async function fetchEmployees(queryParams) {
		try {
			let response = await employeeApi.getEmployees(queryParams);
			if (response && response?.data) {
				const empDta = response?.data?.employees?.map((empData) => {
					return {
						...empData,
						isEmpSelected: false,
						isReplaceLoad: false,
						isResentCardLoading: false,
						pageNumber: 0,
					};
				});
				setEmployeeTableData([...empDta]);
				setMobileViewData(response?.data?.employees);
				settotalCount(response?.data?.totalCount);
			}
		} catch (err) {
			setEmployeeTableData([]);
			setMobileViewData(null);
		}
		getCounts();
	}

	async function getCounts() {
		try {
			if (getEmployeeStatusCountApiCalled.current === false) {
				getEmployeeStatusCountApiCalled.current = true;
				let { data } = await employeeApi.getEmployeeStatusCount();
				setEmployeeCount({
					...employeeCount,
					...data,
				});
			}
		} catch (err) {}
	}

	async function fetchEmployeesForDownload(queryParams) {
		setemployeeListForDownload({
			...employeeListForDownload,
			loading: true,
		});
		try {
			let response = await employeeApi.activeEmployeesDownload(queryParams);

			if (response && response.data) {
				setemployeeListForDownload({
					...employeeListForDownload,
					loading: false,
				});
				const downloadUrl = window.URL.createObjectURL(new Blob([response.data], {}));
				const link = document.createElement('a');
				link.href = downloadUrl;
				link.setAttribute('download', `EmployeeList.xlsx`);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		} catch (err) {
			setemployeeListForDownload({
				data: [],
				loading: false,
			});
		}
	}

	function setMobileViewData(employees, selectedEmployeeInfo = selectedEmployee) {
		if (!employees) {
			setEmployeTableSelect([]);
			setEmployeTableNotSelect([]);
			return;
		}
		let { selectedData, notSelectedData } = employees?.reduce(
			(acc, employee) => {
				if (selectedEmployeeInfo.indexOf(employee?.employeeCode) >= 0) {
					acc.selectedData.push(employee);
				} else {
					acc.notSelectedData.push(employee);
				}
				return acc;
			},
			{
				selectedData: [],
				notSelectedData: [],
			}
		);
		setEmployeTableSelect(selectedData);
		setEmployeTableNotSelect(notSelectedData);
	}

	async function fetchFilterLookupData(status = 'ACTIVE') {
		let searchParams = {
			status: status,
		};
		try {
			let response = await employeeApi.getFilterLookupData(searchParams);
			if (response && response?.data) {
				Object.keys(response?.data).map((val) => {
					return response?.data[val]?.map((val1) => (val1.isSelected = false));
				});

				delete response?.data?.cardStatuses;
				delete response?.data?.emiratesIdStatuses;
				setFilterData({ ...response.data });
			}
		} catch (err) {}
	}

	function handleTabChange(activeTab, queryParams) {
		setSelectedTab(activeTab);
		let updatedSelectedFilterCount = { ...selectedFilterCount };
		let otherParams = { ...queryParams, ...accountType };
		Object.keys(filterData).forEach((key) => {
			let queryKey = queryParamFieldMapping[key];
			let selected = filterData[key].filter((data) => data.isSelected);
			updatedSelectedFilterCount[key] = selected.length;
			if (selected.length > 0) {
				otherParams[queryKey] = selected
					.reduce((acc, data) => {
						acc.push(data[labelFieldMapping[key]]);
						return acc;
					}, [])
					.join(',');
			}
		});
		getEmployeeDetails(getStatusForTab(activeTab), otherParams);
	}

	function getStatusForTab(activeTab) {
		setactiveTab(activeTab);
		let status = '';
		switch (activeTab) {
			case '1':
				status = 'ACTIVE';
				break;
			case '2':
				status = 'CREATINGACCOUNTNO';
				break;
			case '3':
				status = 'PENDINGVERIFIERAPPROVAL';
				break;
			case '4':
				status = 'DELETEACCOUNT';
				break;
			default:
				status = 'ACTIVE';
				break;
		}
		return status;
	}

	function getPageNumber(activeTab) {
		switch (activeTab) {
			case '1':
				return activeAccountPagination;
			case '2':
				return creatingAccountPagination;
			case '3':
				return verifyApprovalPagination;
			case '4':
				return deletedAccountPagination;
			default:
				return activeAccountPagination;
		}
	}

	const handleTableChange = ({ current }, type) => {
		switch (type) {
			case 'activeAccountPagination':
				setactiveAccountPagination({
					...activeAccountPagination,
					pagination: {
						...activeAccountPagination.pagination,
						current,
					},
				});
				handleTabChange('1', { pageNumber: current });
				break;
			case 'creatingAccountPagination':
				setcreatingAccountPagination({
					...creatingAccountPagination,
					pagination: {
						...creatingAccountPagination.pagination,
						current,
					},
				});
				handleTabChange('2', { pageNumber: current });
				break;
			case 'verifyApprovalPagination':
				setverifyApprovalPagination({
					...verifyApprovalPagination,
					pagination: {
						...verifyApprovalPagination.pagination,
						current,
					},
				});
				handleTabChange('3', { pageNumber: current });
				break;
			case 'deletedAccountPagination':
				setdeletedAccountPagination({
					...deletedAccountPagination,
					pagination: {
						...deletedAccountPagination.pagination,
						current,
					},
				});
				handleTabChange('4', { pageNumber: current });
				break;

			default:
				break;
		}
	};

	function handleFilterChange(updatedFilterData) {
		let queryParams = {};
		let updatedSelectedFilterCount = { ...selectedFilterCount };
		Object.keys(updatedFilterData).forEach((key) => {
			let queryKey = queryParamFieldMapping[key];
			let selected = updatedFilterData[key]?.filter((data) => data?.isSelected);
			updatedSelectedFilterCount[key] = selected.length;
			if (selected.length > 0) {
				queryParams[queryKey] = selected
					.reduce((acc, data) => {
						acc.push(data[labelFieldMapping[key]]);
						return acc;
					}, [])
					.join(',');
			}
		});

		setSelectedFilterCount({ ...updatedSelectedFilterCount });
		updateTotalFilterCount(updatedSelectedFilterCount);

		let status = getStatusForTab(selectedTab);
		let {
			pagination: { current: pageNumber },
		} = getPageNumber(selectedTab);
		setFilterData({ ...updatedFilterData });
		getEmployeeDetails(status, { ...queryParams, pageNumber });
	}

	function updateTotalFilterCount(selectedFilter) {
		let updatedFilterCount = Object.values(selectedFilter).reduce((value1, value2) => value1 + value2);
		setTotalFilterCount(updatedFilterCount);
	}

	function handleClearAllFilter(isSelected) {
		let updatedFilterData = { ...filterData };
		let updatedSelectedFilterCount = { ...selectedFilterCount };

		Object.keys(updatedFilterData).forEach((key) => {
			updatedFilterData[key].forEach((value) => {
				value.isSelected = isSelected;
			});
			if (isSelected) {
				updatedSelectedFilterCount[key] = updatedFilterData[key].length;
			} else {
				updatedSelectedFilterCount[key] = 0;
			}
		});
		updateTotalFilterCount(updatedSelectedFilterCount);
		setSelectedFilterCount({ ...updatedSelectedFilterCount });
		setFilterData({ ...updatedFilterData });
		getEmployeeDetails();
	}

	async function deleteEmployees() {
		setIsDataLoading(true);
		try {
			let response = await employeeApi.deleteManageEmployees([emp_Id]);
			if (response && response.message) {
				setSelectedEmployee([]);
				getEmployeeDetails();
				setpopupVisible(true);
				setPopupMessage({
					titleMessage: response?.message,
					subMessage: null,
				});
			}
			setIsDataLoading(false);
		} catch (err) {
			setIsDataLoading(false);
		}
	}
	const closePopup = () => {
		let queryParams = {
			status: 'ACTIVE',
			includeEmployeeCounts: true,
			pageSize: dataCountToShow,
			pageNumber: activeAccountPagination?.pagination?.current,
			payrollBlockCheck: false,
		};
		setpopupVisible(false);
		fetchEmployees(queryParams);
	};
	const showModal = (val, val1) => {
		setEmp_Id(val1?.employeeCode);
		setVisible(true);
	};
	const closeModal = () => {
		setVisible(false);
	};
	const afterOpenModal = (e) => {
		deleteEmployees();
		setVisible(false);
	};

	const handleEmpToPortalEmail = (event) => {
		setMailState({ ...mailState, emailId: event });
	};
	return (
		<>
			<PopupComponent
				{...{
					visible,
					isDataLoading,
					closeModal,
					afterOpenModal,
					popupVisible,
					closePopup,
					popupMessage,
					forPopup: props.isInvitePortalPopup,
					handleEmpToPortalEmail,
					setMailState,
					mailState,
					isInviteLoading: props.isEmailDataLoading,
					handleEmpToPortal,
					handleForCancel,
					subscription,
					setSubscription,
					confirmationModelOpen,
					afterConfirmationOpenModal,
					confirmationsRequest,
					cardloading,
					address,
					filedValue: mailState.emailId,
					kycStatus,
				}}
			/>
			<ManageEmployeePresentational
				{...{
					columns: columns,
					dataSource: employeeTableData,
					totalEmpSelect: selectedEmployeeLists.length,
					handleCheckBox,
					handlepassdata1: handlepassdata,
					employetableSelect,
					employetableNotSelect,
					selectedEmployees,
					setEmployeeTableData,
					employeeCount,
					handleTabChange,
					isDataLoading,
					handleFilterChange,
					filterData,
					setFilterData,
					handleClearAllFilter,
					handleDelete: deleteEmployees,
					isData,
					selectedEmployee,
					selectedFilterCount,
					totalFilterCount,
					selectedTab,
					enableFooter,
					enableCutOff,
					activeAccountPagination,
					creatingAccountPagination,
					verifyApprovalPagination,
					deletedAccountPagination,
					handleTableChange,
					totalCount,
					employeeListForDownload,
					onSearch,
					getEmployeeDetailsForDownload,
					buttonRef,
					activeTabs,
					isSubcribed,
					userNavigationData: props.userNavigationData,
					subscription,
					setSubscription,
					employeeDownloadList: props.employeeDownloadList,
					isEmployeeNewDataLoading: props.isEmployeeNewDataLoading,
					userNavigationMenu: props.userNavigationMenu,
					setDataCountToShow,
					dataCountToShow,
				}}
			/>
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		isEmailDataLoading: Selectors.isEmailDataLoading(state),
		emailData: Selectors.emailDataSave(state),
		isInvitePortalPopup: Selectors.isInvitePortalPopup(state),
		currentUser: SelectorsApp.currentUser(state),
		userNavigationData: SelectorsApp.userNavigationData(state),
		newEmployeeData: Selectors.newEmployeeData(state),
		isEmployeeNewDataLoading: Selectors.isEmployeeNewDataLoading(state),
		inviteCardStatus: Selectors.inviteCardStatus(state),
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		emailDataSaga: (mailState) => dispatch(Actions.creators.postEmailData(mailState)),
		updateEmailSaga: (mailState) => dispatch(Actions.creators.updateEmailNewStart(mailState)),
		invitePortalPopup: (flag) => dispatch(Actions.creators.invitePortalPopup(flag)),
		inviteCardStatusSaga: (status) => dispatch(Actions.creators.inviteCardStatus(status)),
		userNavigationMenu: () => dispatch(action.creators.getUserNavigation()),
		employeeDownloadList: () => dispatch(Actions.creators.getEmployeeNewData()),
		userNavigationMenu: () => dispatch(Action.creators.getUserNavigation()),
	};
};
const Connected = connect(mapStateToProps, mapDispatchToProps)(ManageEmployeeFunctional);
const ReducedScreen = withReducer('EmailReducer', reducer, sagas)(Connected);
export default withRouter(ReducedScreen);

import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import ImageComponent from '@sharedComponent/image-component';
import EdenredButtons from '@pages/DesignSystem/button/EdenredButton';
import MultiSelect from '@pages/DesignSystem/Multiselect/MultiSelect';
import Inputbox from '@pages/DesignSystem/Input-Field';
import { DATE_FORMAT, DATE_VALID_MSG, LEAVES_CONSTANTS, popUpMessage } from '@constants/app-constants';
import ETypo from '@pages/DesignSystem/Typo';
import Edenredmodal from '@pages/DesignSystem/Edenredmodal';
import { checkRoles, getObjectLength } from '@helpers';
import { not_focus_icons } from 'src/assets/icons/not_focus';
import { CustomAlertNotification } from '../alert-notification/alert-notification';
import { DateValidate, LeaveRquestDatePicker, SuccessPopUp } from '../customDatePicker';
import { Validate } from '..';
import UploadAttachment from './upload-attachment';
import './leave-request-presentational.scss';
import { useDispatch } from 'react-redux';
import action from '../action';

const RequestLeavePresentational = ({
	leaveDetail,
	submitButton,
	submitMobButton,
	popUp,
	handleSavePopup,
	leaveComfirmPopupFlag,
	isCreateLoading,
	leaveRequestSaveWeb,
	handleDatepickers,
	leaves,
	setIsModalVisible,
	isModalVisible,
	handleOverlap,
	isOverLappingLoad,
	leaveBalanceLists,
	onChange,
	isUnpaidLeave,
	documentDetails,
	employeeData,
	handleTextArea,
	setFileUpload,
	handleSuccessPopups,
	size,
	setSize,
	documentValidLoading,
	isCreateSaved,
	leaveTypeGender,
	isLeaveTypesLoad,
	notification,
	leaveValid,
	leaveDetail1,
	employee,
	isEmployeeRequest,
	isEmpLeaveRequest,
	handleEmpReqSelect,
	cancelLeave,
	leavePopupMsg,
	roles,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const nameLength = useMemo(() => employeeData?.notes?.length, [employeeData]);
	const datepick = employeeData?.startDate > employeeData?.endDate && employeeData?.endDate != '';
	const buttonDisable = employeeData?.nameId && employeeData?.leaveType && employeeData?.startDate && employeeData?.endDate;
	const joiningDate = moment(leaveBalanceLists?.joiningDate).format(DATE_FORMAT?.YYYY_MM_DD);
	const JoinedDate = moment(leaveBalanceLists?.joiningDate).format(DATE_FORMAT?.DD_MM_YYYY);

	const isAfterJoiningDate = employeeData?.nameId && employeeData?.startDate && joiningDate >= employeeData?.startDate;
	useEffect(() => {
		const handleResize = () => {
			const width = window?.innerWidth;
			setSize(width <= 600);
		};
		window.addEventListener('resize', handleResize);
		window.onresize = handleResize();

		return () => {
			dispatch(action.creators.getLeaveValidSuccess(''));
		};
	}, []);
	var buttonDisable1 = leaveValid === 'Leave Valid';
	const handleOnClick = () =>
		history.push({
			pathname: '/employees/viewemployee',
			state: {
				selectedTab: '3',
				tableData: { employeeId: employeeData?.nameId?.split('code:')?.[1]?.split('id:')?.[1]?.split(' ')?.[0] },
			},
		});
	const empRequest = [
		{
			name: 'For Myself',
			param: 'forMyself',
			isSelected: isEmpLeaveRequest?.forMyself,
		},
		{
			name: 'For My Team',
			param: 'forMyTeam',
			isSelected: isEmpLeaveRequest?.forMyTeam,
		},
	];

	return (
		<div className="container-full">
			<ImageComponent className="mobile-logo" src={not_focus_icons.Logo} />
			<Row className="header-container">
				{isLeaveTypesLoad ? (
					<Col xl={19} lg={19} className="content-Loader-leave">
						<DotLoader size={100} color={'#e91d24'} loading />
					</Col>
				) : (
					<>
						<CustomAlertNotification
							{...{
								when: notification,
								message: popUpMessage.cancelMessage,
								okText: 'Yes, I want to leave',
								cancelText: 'Stay on Page',
								onOK: () => true,
								onCancel: () => true,
							}}
						/>
						<Col className="header-inner-container">
							<ETypo bold h2>
								Request Leave
							</ETypo>
							<div className="submit-button-container">
								<EdenredButtons
									{...{
										disabled: !buttonDisable,
										onClick: cancelLeave,
										btnType: 'secondary',
										style: { marginTop: '5px', marginBottom: '0px' },
									}}>
									Cancel
								</EdenredButtons>{' '}
								<EdenredButtons
									{...{
										disabled: !buttonDisable || datepick || !buttonDisable1 || isAfterJoiningDate,
										onClick: size ? submitMobButton : submitButton,
										btnType: 'primary',
										style: { marginTop: '5px', marginBottom: '0px', marginLeft: '6px' },
									}}>
									Submit
								</EdenredButtons>{' '}
							</div>
						</Col>
						{!isEmployeeRequest && getObjectLength(checkRoles(roles, 'Employee')) > 0 && (
							<Col className="employee_request_header">
								<Col xl={24} lg={24} md={24} sm={24} xs={24} className="employee_request">
									{empRequest?.map((data) => (
										<EdenredButtons
											btnType={data?.isSelected ? 'primary' : 'secondary'}
											className="request_leave_emp"
											onClick={() => handleEmpReqSelect(data?.param)}>
											<ETypo medium b1 color={data?.isSelected ? '#FFFFFF' : '#484B52'}>
												{data?.name}
											</ETypo>
										</EdenredButtons>
									))}
								</Col>
							</Col>
						)}
						<Col className="request-leave-container">
							<Col className="request-leave-section">
								<Row className="request-leave-form-container">
									<Col className="input-container">
										<Col className="employee-input-section">
											{!isEmployeeRequest && !isEmpLeaveRequest?.forMyself && (
												<Col className="each-input">
													<ETypo b3 medium className="input-headings">
														Select Employee
													</ETypo>
													{leaveDetail?.map((detail, i) => (
														<MultiSelect
															{...{
																placeholder: 'Select Employees',
																searchHolder: 'Search by Employees ID,Name',
																Option: detail?.value,
																isError: leaveValid === 'Department Not Configured for the Employee' ? true : false,
																value: employeeData?.empCode || null,
																onChange: (e) => onChange(detail?.param, e),
																dropDownValue: 'employeeName',
																dropDownSubValue: 'employeeCode',
																disabled: detail?.checkRule?.(employeeData?.nameId, leaveTypeGender),
																showIcon: false,
															}}
														/>
													))}
												</Col>
											)}
											{leaveValid === 'Department Not Configured for the Employee' ? (
												<Col className="error-message-container">
													<ETypo b4 medium color="#AB0C00">
														{!isEmployeeRequest
															? 'Joining date and department missing.'
															: 'Please ask your manager to update your contract details.'}
													</ETypo>
													{!isEmployeeRequest && (
														<ETypo b4 bold className="clk-here-text">
															<a style={{ color: '#ab0c00' }} onClick={handleOnClick}>
																Click Here to add
															</a>
														</ETypo>
													)}
												</Col>
											) : (
												''
											)}
											<Col className="each-input">
												<ETypo b3 medium className="input-headings">
													Leave Type
												</ETypo>
												{leaveDetail1?.map((detail, i) => (
													<MultiSelect
														{...{
															searchHolder: 'Search',
															placeHolder: 'Leave Type',
															Option: detail?.value,
															value: employeeData?.leaveType,
															onChange: (e) => onChange(detail?.param, e),
															dropDownValue: 'leaveTypeName',
															disabled: detail?.checkRule?.(employeeData?.nameId, leaveTypeGender),
															showTooltip: true,
															toolTipText: (
																<ETypo light b2 color="#FFFFFF">
																	Select employee before
																	<br />
																	select leave type
																</ETypo>
															),
															showIcon: false,
															searchVisibility: false,
														}}
													/>
												))}
												{popUp?.leaveAbliable ? (
													<Col className="error-message-container">
														<ETypo b4 medium color="#AB0C00">
															You cannot apply leave during Probation Period
														</ETypo>
													</Col>
												) : null}
											</Col>
											{leaveValid != '' &&
											leaveValid === 'Department Not Configured for the Employee' ? null : employeeData?.nameId &&
											  !!Object.keys(documentDetails)?.length ? (
												<Col className="validate-box">
													<Col className="visa-passport-Section">
														<Row className="visa-passport-container">
															<Col className="dropdown-validation">
																<Validate {...{ documentDetails, employeeData, documentValidLoading }} />
															</Col>
														</Row>
													</Col>
												</Col>
											) : null}
											<Col className="leave-picker-container">
												<ETypo b3 medium className="input-headings">
													Leave Date
												</ETypo>
												{!isCreateLoading && (
													<Col className="header-sub-container">
														<LeaveRquestDatePicker
															leaveValid={
																leaveValid != 'Leave Valid' &&
																leaveValid != '' &&
																leaveValid != 'Department Not Configured for the Employee'
															}
															handleDatepickers={handleDatepickers}
															employeeData={employeeData}
															disabled={
																!employeeData?.nameId ||
																(employeeData?.nameId && leaveValid === LEAVES_CONSTANTS.LEAVE_VALID)
															}
														/>
													</Col>
												)}
												{leaveValid === 'Leave Valid' ||
												leaveValid === 'Department Not Configured for the Employee' ? null : (
													<Col className="error-message-container">
														<ETypo b4 medium color="#AB0C00">
															{isAfterJoiningDate ? DATE_VALID_MSG?.GREATER_THAN_JOIN + ' ' + JoinedDate : leaveValid}
														</ETypo>
													</Col>
												)}
											</Col>
											{employeeData?.leaveType && employeeData?.nameId && !employeeData?.endDate && !isUnpaidLeave ? (
												<Col className="days-balance-overlapping-container">
													<Row className="overlapping-section">
														<Row className="overlapping-container">
															<ETypo b2 medium className="balance-and-days-style">
																Balance{' '}
																<ETypo b3 light style={{ marginLeft: '8px' }}>
																	{leaveBalanceLists?.balance ?? 0}
																</ETypo>
															</ETypo>
														</Row>
													</Row>
												</Col>
											) : null}
											{employeeData?.endDate ? (
												<DateValidate
													{...{
														size,
														leaves,
														isUnpaidLeave,
														employeeData,
														handleOverlap,
														setIsModalVisible,
														isModalVisible,
														isOverLappingLoad,
														leaveBalanceLists,
														employee,
													}}
												/>
											) : null}
										</Col>
									</Col>
									<Col className="notes-attachment-conatiner">
										<Col className="note-header-container">
											<ETypo b3 medium className="input-headings" color="#181919">
												Note<span className="inner-type"> (Optional)</span>
											</ETypo>
											<Col className="note-leave-container">
												<Inputbox
													className="input-note"
													type="textarea"
													placeholder="Write your message here"
													maxLength="120"
													value={employeeData?.notes}
													onChange={handleTextArea}
													inputType="textarea"
												/>
												<p style={{ paddingTop: 5 }}>{nameLength}/120 Characters</p>
											</Col>
										</Col>
										<Col className="attachement-container request-leave-attachment">
											<ETypo b3 medium className="input-headings" color="#181919">
												Attachment<span className="inner-type"> (Optional)</span>
											</ETypo>
											<div className="attachment-section">
												{!isCreateLoading && (
													<UploadAttachment
														{...{
															size: 6000000,
															setFileUpload,
															acceptedTypes: 'image/png, image/jpeg',
														}}
													/>
												)}
											</div>
										</Col>
									</Col>
								</Row>
							</Col>
						</Col>
					</>
				)}
			</Row>
			<Edenredmodal
				isOpen={leaveComfirmPopupFlag}
				onCancel={handleSavePopup}
				close={handleSavePopup}
				onOkay={leaveRequestSaveWeb}
				title={leavePopupMsg}
				className="request-leave-save-popup"
				loading={isCreateLoading}
			/>
			<SuccessPopUp {...{ handleSuccessPopups, isCreateSaved, popUp }} />
		</div>
	);
};

export default RequestLeavePresentational;
